import { Fragment, useContext, useEffect } from "react";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { Context } from "../App";
import { getActionSetConfirm, icbControllerGenerel02, nodeCRUD } from "../misc/Functions";
import { ActionSetContentEntity, ActionSetPractice, CRUD, DataGridActions, DataGridUpdate, JSONAPITypeId, TypeContext } from "../misc/Types";
import CRUDList from "./CRUDList";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { PRACTICEINIT } from "../misc/Constants";

export default function MyTeams() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('MyTeams');
    const theme = useTheme();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const columns = () => [
        { field: 'id', headerName: 'ID', width: 10 },
        { field: 'teamName', headerName: t('PracticeProgramForm22'), flex: 1, editable: true, },
    ]

    function processRowUpdate() {
        return (dataGridUpdate: DataGridUpdate) =>
            new Promise((resolve, reject) => {
                if (dataGridUpdate.delete) {
                    // delete
                    const teamID = dataGridUpdate.id?.toString()
                    // delete team and practices for team. Do this as back end job!
                    icbControllerGenerel02(state, {
                        opr: "delete_team",
                        teamID: teamID
                    })
                        .then((resp) => {
                            if (resp.ok) {
                                resolve(dataGridUpdate.rows)
                                // find list of practices for team that has been removed
                                const teamPractices = state.allTeams.find(x => x.id === teamID)?.relationships.field_practices.data.map((x: JSONAPITypeId) => x.id)
                                // remove practices from list of read practices
                                const actionSetContentEntityPractices: ActionSetContentEntity = {
                                    type: 'setContentEntity',
                                    contentType: 'node--practice',
                                    data: state.allPractices.filter(x => !teamPractices.includes(x.id)),
                                    initialLoad: true
                                }
                                dispatch(actionSetContentEntityPractices)
                                // remove team from list of read teams
                                const actionSetContentEntityTeams: ActionSetContentEntity = {
                                    type: 'setContentEntity',
                                    contentType: 'node--team',
                                    data: state.allTeams.filter(x => x.id !== teamID),
                                    initialLoad: true
                                }
                                dispatch(actionSetContentEntityTeams)
                                // Init current practice if current practice belongs to team just deleted
                                if (state.curPractice.team && teamID === state.curPractice.team.id) {
                                    const action: ActionSetPractice = { type: 'setPractice', practice: PRACTICEINIT }
                                    dispatch(action)
                                }
                            } else {
                                reject(new Error(resp.error))
                            }
                        })
                } else if (dataGridUpdate.newRow) {
                    // create or update
                    // check team name specified
                    if (!dataGridUpdate.newRow.teamName.trim()) {
                        reject(new Error(t('MyTeams01')))
                        return
                    }
                    // check we don't have team of that name already
                    if (dataGridUpdate.rows.filter(x => x.teamName === dataGridUpdate.newRow!.teamName).length > 1) {
                        reject(new Error(t('MyTeams00')))
                        return
                    }
                    const create = dataGridUpdate.newRow.isNew
                    const teamCreate = {
                        type: 'node--team',
                        attributes: {
                            title: dataGridUpdate.newRow!.teamName,
                        }
                    }
                    const teamUpdate = { ...teamCreate, id: dataGridUpdate.newRow!.id }
                    nodeCRUD(state, dispatch, create ? CRUD.Create : CRUD.Update, create ? teamCreate : teamUpdate)
                        .then((resp) => {
                            // not an error if we get json structure
                            if (resp.data) {
                                resolve(dataGridUpdate.rows)
                            } else {
                                log.error(resp);
                                dispatch(getActionSetConfirm(resp));
                            }
                        })
                } else {
                    resolve(dataGridUpdate.rows)
                }
            })
    }

    return (
        <Fragment>
            <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
                <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                    {t('MyTeams02')}
                </Typography>
                <Divider />
            </Box>

            <CRUDList
                rows={
                    state.allTeams
                        // clubadmin has access to club members teams but club member teams should not be shonw in the list
                        .filter(x => x.relationships.uid.data.id === state.user.data.id)
                        .map((item) => ({ id: item.id, teamName: item.attributes.title }))
                }
                columns={columns()}
                actions={[DataGridActions.save, DataGridActions.cancel, DataGridActions.delete, DataGridActions.edit]}
                processRowUpdate={processRowUpdate}
                labelAddRow={t('Generel12')}
                gridColumnVisibilityModel={{ id: false }}
                columnFocusOnNew="teamName"
                deleteWarning={t('MyTeams03')}
            />
        </Fragment>
    )
}