import { Button, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { DrupalEntity, TypeContext } from "../misc/Types";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Context } from "../App";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import log from "../misc/Logger";
import { exerciseImage, exerciseVideo, getActionSetConfirm } from "../misc/Functions";
import DialogExerciseDurationAndFocus from "./DialogExerciseDurationAndFocus";

export interface PropsDialogExerciseDetails {
    open: boolean,
    onClose: () => void,
    exercise: DrupalEntity,
    addOption: boolean,
}

// Click on exercise image and show this dialog with exercise details
export function DialogExerciseDetails(props: PropsDialogExerciseDetails) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('DialogExerciseDetails');

    // Display video or image
    const [displayImage, setDisplayImage] = useState('block');
    const [displayVideo, setDisplayVideo] = useState('none');
    const [openDialogExerciseDurationAndFocus, setOpenDialogExerciseDurationAndFocus] = useState(false)
    useEffect(() => {
        if (displayVideo === 'block') {
            startVideo()
        }
    }, [displayVideo])
    // Value for repostion of video play icon 
    // const [videoPlayIconLeft, setVideoPlayIconLeft] = useState(0);
    const fileIDVideo = props.exercise.relationships && props.exercise?.relationships.field_exercise_video.data?.id;
    // const fileVideo = fileIDVideo && state.allFiles.find(x => x.id === fileIDVideo)?.attributes.uri.url;
    const fileIDImage = props.exercise.relationships && props.exercise?.relationships.field_exercise_board_image.data?.id;
    // const fileImage = fileIDImage && state.allFiles.find(x => x.id === fileIDImage)?.attributes.uri.url;
    const imageRef = useRef<HTMLImageElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    function startVideo() {
        if (videoRef.current && videoRef.current.paused) {
            // Make sure video is of same size as image. Otherwise, text elements below video are repositioned
            videoRef.current.width = imageRef.current!.width;
            videoRef.current.height = imageRef.current!.height;
            // Why use promise? Pls see https://developer.chrome.com/blog/play-request-was-interrupted/        
            let playPromise = videoRef.current.play();
            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    // Clear msg. I.e. user is waiting for video to be converted and uploaded to CDN
                    dispatch(getActionSetConfirm());
                })
                // .catch(error => {
                // });
            }
        }
    }

    return (
        <Fragment>

            <Dialog
                open={props.open}
                onClose={props.onClose}
                maxWidth='xs'
                PaperProps={{
                    style: {
                        borderRadius: 16,
                    },
                }}
            >
                <DialogTitle id="alert-dialog-description" fontWeight={'bold'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {props.exercise.attributes.title}
                    <IconButton onClick={props.onClose} edge="end" aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ padding: '8px 24px' }}> {/* Adjust padding as needed */}
                    <CardMedia
                        sx={{ display: displayVideo }}
                        component='video'
                        src={exerciseVideo(state, fileIDVideo)}
                        onError={() => {
                            log.info('try reload videos: ' + exerciseVideo(state, fileIDVideo));
                            dispatch(getActionSetConfirm()); // need to reset text, otherwise texts are stacked in the meesage!
                            dispatch(getActionSetConfirm(t('DialogExerciseDetails02')));
                            setTimeout(() => {
                                // Add cache breaker to image src so nothing will be cached and do a full image lookup
                                let src = exerciseVideo(state, fileIDVideo) + "?" + new Date().getTime();
                                if (videoRef && videoRef.current && videoRef.current.src) {
                                    videoRef.current.src = src;
                                    startVideo();
                                }
                            }, 10000)
                        }}
                        preload="none"
                        loop
                        muted
                        controls
                        controlsList="nodownload"
                        ref={videoRef}
                    />
                    <CardMedia
                        sx={{ display: displayImage, maxHeight: '300px', objectFit: 'contain', margin: 'auto' }}
                        component='img'
                        src={exerciseImage(state, fileIDImage, props.exercise ? props.exercise.id : '')}
                        alt='Exercise image.'
                        ref={imageRef}
                    />
                    {/* reposition video play icon 36px becuase it fits well with icon size of 24x24, ie PlayCircleOutlineIcon */}
                    {/* <PlayCircleOutlineIcon sx={{ display: fileVideo ? 'block' : 'none', position: 'relative', top: '-36px', left: `${videoPlayIconLeft}px` }} /> */}

                    <Typography sx={{ fontSize: { xs: 10, sm: 12 }, whiteSpace: 'pre-wrap' }} marginTop={2}>
                        {props.exercise.attributes.field_description?.substring(0, 600)}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between" marginLeft={2} marginRight={2} marginBottom={2}>
                        {
                            props.addOption &&
                            <Button
                                variant='outlined'
                                color='secondary'
                                sx={{ borderRadius: 20 }}
                                onClick={() => setOpenDialogExerciseDurationAndFocus(true)}
                            >
                                <Typography sx={{ fontSize: { xs: 10, sm: 12 } }}>
                                    {t('ExerciseCard00')}
                                </Typography>
                            </Button>
                        }
                        {
                            // Toggle image or video if video is available
                            fileIDVideo &&
                            <Button
                                onClick={() => {
                                    if (displayVideo === 'block') {
                                        setDisplayImage('block');
                                        setDisplayVideo('none');
                                    } else {
                                        setDisplayImage('none');
                                        setDisplayVideo('block');
                                    }
                                }}
                                sx={{ borderRadius: 20 }}
                                autoFocus
                                variant="outlined"

                            >
                                <Typography sx={{ fontSize: { xs: 10, sm: 12 } }}>
                                    {displayVideo === 'block' ? t('DialogExerciseDetails00') : t('DialogExerciseDetails01')}
                                </Typography>
                            </Button>
                        }
                    </Grid>
                </DialogActions>
            </Dialog >

            <DialogExerciseDurationAndFocus
                open={openDialogExerciseDurationAndFocus}
                onClose={(ok: boolean) => {
                    setOpenDialogExerciseDurationAndFocus(false) // close child dialog
                    if (ok) {
                        props.onClose() // close this dialog
                    }
                }}
                exercise={props.exercise}
            />

        </Fragment>
    )
}