import { Box, FormControl, InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import log from "../misc/Logger";
import { useContext } from "react";
import { Context } from "../App";
import { TypeContext } from "../misc/Types";

interface PropsTeamDropdown {
    handleChange: (value: string) => any,
    // teams: string[],
    selected: string,
    firstElement?: string,  // optionally first element for team list
}

export default function TeamDropdown(props: PropsTeamDropdown) {
    log.debug('TeamDropdown');
    const { state } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: '450px', margin: '0 auto' }}>
            <FormControl fullWidth>
                <InputLabel
                    id="team-select-label"
                >
                    {t('PracticeProgramForm22')}
                </InputLabel>
                <Select
                    labelId="team-select-label"
                    id="team-select"
                    value={props.selected}
                    variant="outlined"
                    label={t('PracticeProgramForm22')}
                    onChange={(event) => { props.handleChange(event.target.value) }}
                    size="small"
                    sx={{ color: 'grey' }}
                    MenuProps={{
                        sx: {
                            '& .MuiMenuItem-root': {
                                fontFamily: '"PT Sans", sans-serif',
                                '&:not(:last-child)': {
                                    borderBottom: '1px solid #ddd', // Add divider
                                },
                            },
                        },
                    }}
                >
                    {
                        // Allow for potential static first element in drop down list
                        props.firstElement &&
                        <MenuItem
                            value={props.firstElement}
                            sx={{
                                color: theme.palette.text.primary,
                            }}
                        >
                            {props.firstElement}
                        </MenuItem>
                    }
                    {state.allTeams
                        .filter(x => x.relationships.uid.data.id === state.user.data.id) // only show users own teams
                        .map((item, index) => (
                            <MenuItem
                                key={index}
                                value={item.attributes.title}
                                sx={{
                                    color: theme.palette.text.primary,
                                }}
                            >
                                {item.attributes.title}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl >
        </Box>
    )
}