import { Fragment, useContext, useState } from "react";
import { AppBar, Avatar, Box, Divider, IconButton, Tab, Tabs, Tooltip, Typography, } from "@mui/material";
import { Context } from "../App";
import { OperationMode, TypeContext, UserType, buttonInList } from "../misc/Types";
import { useTranslation } from "react-i18next";
import LanguageICB from "./LanguageICB";
import { CDN, DEFAULT_GROUP_EXERCISE, DEFAULT_GROUP_PLAY, ICB_USER_ID, SHOW_ALL_GROUP } from "../misc/Constants";
import { getImageURL, goHome, } from "../misc/Functions";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { MenuMain } from "./MainMenu";
// import _ from "lodash";
import log from "../misc/Logger";
import { useTheme } from "@mui/material";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    '& .MuiTab-root': {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      border: '1px solid transparent',
      // transition: 'border 0.2s',
      '&:not(:first-of-type)': {
        borderRadius: 7,
      },
      '&:first-of-type': {
        borderRadius: 7,
        marginLeft: 0,
      },
      // Estilos para pestañas no seleccionadas
      '&:not(.Mui-selected)': {
        backgroundColor: theme.palette.action.selected,
      },
      // Estilos para pestañas seleccionadas
      '&.Mui-selected': {
        color: theme.palette.background.default,
        fontWeight: 'bold',
      },
      // Estilo al pasar el ratón
      '&:hover': {
        border: `1px solid ${theme.palette.divider}`, // Borde al pasar el ratón
      },
    },
  },
}));

interface PropsICBAppBar {
  club: string | undefined,
}

export default function ICBAppBar(props: PropsICBAppBar) {
  const { state, dispatch } = useContext(Context) as TypeContext;
  const { t } = useTranslation();
  log.debug('ICBAppBar');

  const navigate = useNavigate();
  const theme = useTheme();
  // 'number of minutes in practice' progress bar
  const [openMainMenu, setOpenMainMenu] = useState(false);
  const [anchorElMainMenu, setAnchorElMainMenu] = useState<null | HTMLElement>(null);

  const fileIDAuthorImage = state.user.data.relationships?.user_picture?.data?.id;
  const authorImage = fileIDAuthorImage && state.allFiles.find(x => x.id === fileIDAuthorImage)?.attributes.uri.url;
  // const progress = state.curPractice.selectedExercises.reduce((total, exercise) => total + (exercise.durationMinutes || 0), 0);//selectedExercises.reduce((total, exercise) => total + (exercise.durationMinutes || 0), 0);
  const location = useLocation(); // get current page for logging purposes

  const exercisePools: buttonInList[] = [
    {
      label: t("ICB"),
      noShow: false,
      toggleValue: 'icb'
    },
    {
      label: props.club || '', // state.club,
      noShow: !props.club, //state.club,
      toggleValue: 'club'
    },
    {
      label: t('ICBAppBar12'),
      noShow: state.user.data.attributes.field_user_type === UserType.clubadmin,
      toggleValue: 'mine'
    },
  ];

  // Find text to show in the middle of the topbar
  const textForRoute = () => {
    switch (location.pathname) {
      case '/home':
        return t('DrawerLandscape00');
      case '/practices':
        return t('DrawerLandscape02');
      case '/clubdocuments':
        return t('DrawerLandscape11');
      case '/exercises':
        return t('DrawerLandscape01');
      case '/myplaybooks':
        return t('DrawerLandscape15');
      case '/concepts':
        return t('DrawerLandscape13');
      case '/formgetpracticereport':
        return t('DrawerLandscape03');
      case '/groups':
        return t('DrawerLandscape05');
      case '/myteams':
        return t('DrawerLandscape10');
      case '/coaches':
        return t('DrawerLandscape06');
      case '/profile':
        return t('DrawerLandscape07');
      case '/setsubscription':
        return t('ICBAppBar02');
      case '/createplay':
        return t('ICBAppBar13');
      case '/exercisecreate':
        return t('DrawerLandscape04');
      default:
        return '';
    }
  };

  // When exercise pool or exercise group is selected then remove selected dialogs that block
  // view to the exercises we are about to show
  // function removeDialogs() {
  //   // On small screens we might have a case where the practice program is shown and you see
  //   // no action when selected group is changed. To prevent that we make sure practice program
  //   // is not shown
  //   if (state.portrait && state.showPracticeProgram) {
  //     let action: ActionShowPracticeProgram = { type: 'showPracticeProgram', show: false };
  //     dispatch(action);
  //   }
  // }

  // Handle change of exercise pool
  // const handleChangeExercisePool = (event: React.SyntheticEvent<Element, Event> | null, newValue: any) => {
  // const handleChangeExercisePool = (newValue: any) => {
  //   removeDialogs();
  //   dispatch({ type: 'setSelectedExercisePool', pool: newValue });

  //   if (state.user.login.current_user.uid === 0 && newValue === 'mine') {
  //     // Anomymous users dont have library - give info
  //     dispatch(getActionSetConfirm(t('Generel00'), t('AccountCaptcha08'), () => navigate('/signup')));
  //   }
  //   //WE GET RID OF THE NEXT LINE SINCE WE WILL AIM FOR A SPECIFIC ID WHEN WE WANT TO SCROLL ALL THE WAY UP WHEN CHANGING POOLS. 
  //   //THIS WAS NEEDED IN THE MOMENT WE MADE INDEPENDENT SCROLLING OF EXERCISES AND PRACTICEPROGRAM
  //   // window.scrollTo(0, 0);

  //TO SCROLL NOW, WE LOOK FOR A TRANSPARENT -DIVIDER- WITH ID 'ScrollUpExercisesPortrait or ScrollUpExercisesLandscape' AND WE PUT IT INTO VIEW
  //TIMEOUT IS NEEDED!!!
  const scrollToTopId = state.portrait ? 'initialViewExercisesPortrait' : 'initialViewExercisesLandscape';
  setTimeout(() => {
    const scrollToTop = document.getElementById(scrollToTopId);
    if (scrollToTop) {
      scrollToTop.scrollIntoView({ behavior: 'smooth' });
    }
  }, 0);

  //   locationICB.pathname !== '/icb' && navigate('/icb');
  // };

  // Handle change or exercise group
  // const handleChangeSelectGroup = (event: React.SyntheticEvent | null, newValue: any) => {
  // const handleChangeSelectGroup = (selectedGroup: DrupalEntity) => {
  // function handleChangeSelectGroup(_event: React.SyntheticEvent, selectedGroupID: string) {

  //   // dispatch({ type: 'setSelectedGroup', group: state.allGroups[newValue] });
  //   dispatch({ type: 'setSelectedGroup', group: state.allGroups.find(x => x.id === selectedGroupID) });
  //   removeDialogs();
  //   // SAME CONCEPT AS HANDLECHANGEEXERCISEPOOL
  //   // window.scrollTo(0, 0);
  //   //TIMEOUT IS NEEDED!!!
  //   const scrollToTopId = state.portrait ? 'initialViewExercisesPortrait' : 'initialViewExercisesLandscape';
  //   setTimeout(() => {
  //     const scrollToTop = document.getElementById(scrollToTopId);
  //     if (scrollToTop) {
  //       scrollToTop.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   }, 0);

  //   locationICB.pathname !== '/icb' && navigate('/icb');
  // };

  // Practice duration. See https://mui.com/material-ui/react-progress/
  // function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  //   return (
  //     <Box sx={{ display: 'flex', alignItems: 'center' }} marginLeft={1} marginRight={1}>
  //       <Box sx={{ width: '100%', mr: 1 }}>
  //         <LinearProgress variant="determinate" {...props} />
  //       </Box>
  //       <Box sx={{ minWidth: 35 }} >
  //         <Typography variant="body2" color="text.secondary">
  //           {`${Math.round(props.value,)}\u00A0min`}
  //         </Typography>
  //       </Box>
  //     </Box>
  //   );
  // }

  // // Function to determine the color of the progress bar
  // const getProgressBarColor = (progress: number) => {
  //   if (progress <= 25) return 'red';
  //   if (progress <= 55) return 'yellow';
  //   return 'green'; // Will also be green when progress is 100%
  // };

  // get default value for selected tab
  function getControlledValueForSelectedGroup() {
    let res = ''
    if (state.allGroups.length === 0)
      res = ''
    else if (state.selectedGroup)
      res = state.selectedGroup.id!
    else if (state.operationMode === OperationMode.exercise)
      res = DEFAULT_GROUP_EXERCISE
    else
      res = DEFAULT_GROUP_PLAY
    // console.log(res, state.selectedGroup?.id)
    return res
  }

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        flexDirection: 'column',
        backgroundColor: theme.palette.background.default,

        height: !['/icbdrills', '/icbplays'].includes(location.pathname)
          ? '50px'
          : {
            xs: '80px',
            sm: '89px',
          },
        // height: !['/icbdrills', '/icbplays'].includes(location.pathname) ? '50px' : '89px',
        // height: { xs: 100, sm: 105 },
        ...(!state.portrait && { marginLeft: '57px', width: `calc(100% - 57px)` }), // Apply these styles only when landscapeBlock is 'block'
      }}>

      {/* Number of minutes in practice so far */}
      {/* <Box >
        <LinearProgressWithLabel value={progress} />
      </Box> */}

      {/* home icon, exercise pool selection, user drowdown menu */}
      <Box display={'flex'} width={1} sx={{ justifyContent: 'space-between' }}>

        {/* WE RELOAD NOW FROM MAINMENU */}
        {/* home icon, show only ICB exercise pool */}
        {/* {
          state.nativeApp && state.gotUsersGroupsExercises ? // make sure user do not refresh too fast - it freezes the app
            <Button onClick={() => { reloadApp(state.nativeApp, location.pathname) }}>
              <RefreshIcon sx={{ color: 'grey' }} />
            </Button>
            :
            <Button onClick={() => { goHome(dispatch, navigate) }}>
              <img height={32} src={getImageURL(theme.palette.mode === 'dark' ? 'logoICBWhite.webp' : 'logoICBBlue.webp')} />
            </Button>
        } */}

        <IconButton onClick={() => { goHome(dispatch, navigate) }}>
          <img height={32} src={getImageURL(theme.palette.mode === 'dark' ? 'logoICBWhite.webp' : 'logoICBBlue.webp')} />
        </IconButton>



        {/* Exercise pool, ICB, club or mine */}
        {
          <StyledTabs
            value={state.selectedExercisePool}
            // onChange={handleChangeExercisePool}
            variant="scrollable"
            aria-label="exercise pool"
            TabIndicatorProps={{
              style: { display: 'none' }
            }}
            onClick={() => {
              if (state.portrait)
                dispatch({ type: 'showPracticeProgram', show: false });
            }}
          >
            {
              ['/icbdrills', '/icbplays'].includes(location.pathname) &&
              exercisePools.filter(x => !x.noShow).map((item, index) => (
                <Tab // Replace ToggleButton with Tab
                  key={index}
                  value={item.toggleValue || 'will never be used'}
                  aria-label={item.toggleValue}
                  label={item.label} // Use label prop to display the text
                  // Use trick below to trigger action when already selected tab in the tabs group is reselected
                  onClick={() => {
                    // state.selectedExercisePool === item.toggleValue && handleChangeExercisePool(item.toggleValue);
                    // 11/11/24 I don't this this function call is needed! handleChangeExercisePool(item.toggleValue);
                    dispatch({ type: 'setSelectedExercisePool', pool: item.toggleValue });
                  }}
                  sx={{
                    marginTop: 1,
                    fontSize: { xs: 10, sm: 12 },
                    justifyContent: 'center', // Add this to center the tabs
                    minHeight: { xs: '25px', sm: '30px' },
                    height: { xs: '25px', sm: '30px' }, // Tab height from 48 -> 36
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.secondary.main,
                    }
                  }}
                />
              ))
            }
          </StyledTabs>
        }
        {
          !['/icbdrills', '/icbplays'].includes(location.pathname) &&
          <Typography
            sx={{
              color: theme.palette.text.primary,
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              fontWeight: 'bold',
              fontSize: { xs: 16, sm: 17 },
            }}
          >
            {textForRoute()}
          </Typography>
        }

        {/* Popup menu OR language selection */}
        {/* We have box in an optional box becuase we always want at least one box */}
        {/* With flexbox justifyContent we have the togglebutton for exercise pools, flexitem 2/3, close being centered and thats want we want */}
        {
          state.loggedIn === 1 ?
            // Popup menu
            <Fragment>
              {/* Popup menu button. Login icon, user avatar or user picture */}
              <Tooltip title={state.debug ? `${state.user.data.attributes.name} (debug: ${state.user.data.attributes.drupal_internal__uid})` : `${state.user.data.attributes.name}`}>
                <span>
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      if (state.portrait) {
                        setOpenMainMenu(true);
                        setAnchorElMainMenu(event.currentTarget);
                      }
                    }}
                    color="inherit"
                    disabled={!state.portrait} // disable the button in landscape mode
                  >
                    {
                      authorImage ?
                        <img
                          src={`${CDN}/${authorImage}.webp`}
                          height="32"
                          width="32"
                          style={{ borderRadius: "50%", height: '32px', width: '32px' }}
                          alt="Imagen del Autor"
                        />
                        : <Avatar sx={{ bgcolor: (theme) => theme.palette.secondary.main, width: 32, height: 32, fontSize: "0.9rem" }}>
                          {state.user.data.attributes.display_name?.substring(0, 2).toUpperCase()}
                          {/* {state.practiceProgramDirty ? 'D' : 'C'} */}
                        </Avatar>
                    }
                  </IconButton>
                </span>
              </Tooltip>
              {/* Popup menu items */}
              {state.portrait && <MenuMain
                open={openMainMenu}
                onClose={() => setOpenMainMenu(false)}
                anchorEl={anchorElMainMenu}
              />}
            </Fragment>

            // Language selection. For an anonymous user who tests the app
            : <LanguageICB />
        }
      </Box>

      {/* Groups as tab interface */}
      {/* If I don't set the color I can't see the scrollbar icon!!!! */}
      {
        ['/icbdrills', '/icbplays'].includes(location.pathname) &&

        <Box sx={{ color: theme.palette.text.primary }}>
          <StyledTabs
            value={getControlledValueForSelectedGroup()}
            variant="scrollable"
            TabIndicatorProps={{
              style: { display: 'none' }
            }}
            // 11/11/24 i don't this this function call is needed onChange={handleChangeSelectGroup}
            onChange={(_event: React.SyntheticEvent, selectedGroupID: string) => {
              console.log('onChange', selectedGroupID)
              dispatch({ type: 'setSelectedGroup', group: state.allGroups.find(x => x.id === selectedGroupID) });
            }}
            onClick={() => {
              if (state.portrait)
                dispatch({ type: 'showPracticeProgram', show: false });
            }}
          >
            {
              getControlledValueForSelectedGroup() !== "" &&
              state.allGroups.filter(x =>
                (state.selectedExercisePool !== 'icb'
                  || !x.relationships.uid
                  || x.relationships.uid.data.id === ICB_USER_ID)
                && (x.attributes.field_group_type === state.operationMode
                  || x.id === SHOW_ALL_GROUP)
              )
                .map((item, index) => (
                  <Tab
                    key={index}
                    label={item.attributes.title} // text in tab on display
                    value={item.id}
                    // onClick={() => { handleChangeSelectGroup(item) }}
                    sx={{
                      fontSize: { xs: 10, sm: 12 },
                      minHeight: { xs: '25px', sm: '30px' },
                      height: { xs: '25px', sm: '30px' }, // Tab height from 48 -> 36
                      '&.Mui-selected': {
                        backgroundColor: theme.palette.primary.main,
                      }
                    }}
                  />
                ))
            }
          </StyledTabs>
          <Box sx={{ marginTop: { xs: -2, sm: -1 } }}>
            <Divider />
          </Box>
        </Box>
      }

      {
        !['/icbdrills', '/icbplays'].includes(location.pathname) &&
        <Box >
          <Divider />
        </Box>
      }
    </AppBar >
  )
}
