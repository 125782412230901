import { Box, Divider, Typography, useTheme } from '@mui/material';
import { Fragment, useContext, useEffect, useState } from 'react';
import { TypeContext, DrupalEntity } from '../misc/Types';
import { Context } from '../App';
import { useTranslation } from 'react-i18next';
import log from "../misc/Logger";
import SearchField from './SearchField';
import { DialogShowVideo } from './DialogShowVideo';
import FiberNewIcon from '@mui/icons-material/FiberNew';

export default function Concepts() {
    const { state } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('Concepts');
    const theme = useTheme();
    const [conceptFileID, setConceptFileID] = useState(''); // Controls open/close of DialogShowVideo
    const [concept, setConcept] = useState(''); // Title for video dialog
    const [filteredConcepts, setFilteredConcepts] = useState<Array<DrupalEntity>>([])
    const [dialogShowVideo, setDialogShowVideo] = useState(false)

    // When concepts are shown always show from the top!
    window.scrollTo(0, 0);

    // Set flag on new concept. Determined by configuration
    function newConcept(concept: DrupalEntity): boolean {
        let nowMinusXDays = Date.now() - parseInt(state.configuration[0].attributes.field_days_to_flag_new_concept) * 24 * 60 * 60 * 1000;
        return (Date.parse(concept.attributes.created) > nowMinusXDays || Date.parse(concept.attributes.changed) > nowMinusXDays);
    }

    // Filter list of concepts based on filter string
    function setConceptsList(filter: string) {
        const filteredConceptsLocal = state.allConcepts
        .filter((concept: DrupalEntity) =>
            concept.attributes.title.toLowerCase().includes(filter.toLowerCase())
        )
        .sort((a: DrupalEntity, b: DrupalEntity) => {
            const isNewA = newConcept(a);
            const isNewB = newConcept(b);

            if (isNewA && !isNewB) return -1;
            if (!isNewA && isNewB) return 1;

            return a.attributes.title.localeCompare(b.attributes.title);
        });
        setFilteredConcepts(filteredConceptsLocal)
    }

    // Initial show of concepts when data has been retrived and no filter strign
    useEffect(() => {
        setConceptsList('')
    }, [state.allFiles, state.allConcepts])

    return (
        <Fragment>
            <DialogShowVideo
                open={dialogShowVideo}
                onClose={() => setDialogShowVideo(false)}
                fileIDVideo={conceptFileID}
                concept={concept}
            />
            <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
                <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                    {t('Concepts00')}
                </Typography>
                <Divider />

                <Box paddingTop={3}>
                    <SearchField onSearch={setConceptsList} /> {/* Add SearchField */}
                </Box>

                <Box paddingTop={2}>
                    {
                        filteredConcepts.map((concept: DrupalEntity, index: number) => (
                            <Fragment key={index}>
                                <Typography
                                    variant='body2'
                                    sx={{
                                        fontSize: { xs: 12, sm: 14 },
                                        textTransform: 'none',
                                        fontWeight: newConcept(concept) ? 'bold' : 'normal',
                                        color: newConcept(concept) ? theme.palette.secondary.main : theme.palette.text.primary,
                                        cursor: 'pointer',
                                        padding: '8px 0',
                                        display: 'flex', // Add flex display
                                        alignItems: 'center', // Align elements in the center
                                        '&:hover': {
                                            backgroundColor: theme.palette.action.hover,
                                        },
                                    }}
                                    onClick={() => {
                                        setConceptFileID(concept.relationships.field_concept_video.data.id);
                                        setConcept(concept.attributes.title);
                                        setDialogShowVideo(true)
                                        //throw new Error('forced error') FOR TEST PURPOSES
                                    }}
                                >
                                    <Box component="span" sx={{ marginRight: 1, color: theme.palette.text.secondary }}>•</Box>
                                    {concept.attributes.title}
                                    
                                    {newConcept(concept) && (
                                        <Box component="span" sx={{ marginLeft: 1, color: theme.palette.secondary.main, fontSize: { xs: 10, sm: 14 }, fontWeight: 'normal', display: 'flex', alignItems: 'center' }}>
                                            <FiberNewIcon />
                                        </Box>
                                    )}
                                </Typography>
                                {index < filteredConcepts.length - 1 && <Divider />} {/* Use filteredConcepts */}
                            </Fragment>
                        ))
                    }
                </Box>
            </Box>
        </Fragment>
    );
}