import { useContext, useState } from "react";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { Context } from "../App";
import { mapPractice, } from "../misc/Functions";
import { ActionSetPractice, PracticeBetter, TypeContext, } from "../misc/Types";
import { DataGrid, GridEventListener } from "@mui/x-data-grid";
import DialogDownloadSendBoth from "./DialogDownloadSendBoth";
import { Box, Divider, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, Typography, useTheme } from "@mui/material";

export default function MyPractices() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('MyPractices');
    const theme = useTheme();

    const [openDialogDownloadSendBoth, setOpenDialogDownloadSendBoth] = useState(false);
    const [selectedTeamFilter, setSelectedTeamFilter] = useState('');

    // Give running number to each practice. New count for each team
    function setPracticeNumber(practices: Array<any>) {
        if (practices.length === 0)
            return practices
        let team: string = practices[practices.length - 1].team
        let num: number = 1
        for (let i = practices.length - 1; i > -1; i--) {
            if (practices[i].team !== team) {
                num = 1
                team = practices[i].team
            }
            practices[i].pnum = num++;
        }
        return practices;
    }

    // Sort by team name and practice date reverse
    function compareFNTeamNamePracticeDate(a: PracticeBetter, b: PracticeBetter) {
        const aTeamName = a.team.attributes.title;
        const bTeamName = b.team.attributes.title;
        const aPracticeDate = a.date
        const bPracticeDate = b.date
        if (aTeamName < bTeamName)
            return -1
        else if (aTeamName > bTeamName)
            return 1
        else if (aPracticeDate < bPracticeDate)
            return 1
        else if (aPracticeDate > bPracticeDate)
            return -1
        else
            return 0
    }

    // Data columns
    const columns = () => [
        { field: 'pnum', headerName: '', maxWidth: 10, sortable: false },
        { field: 'team', headerName: t('PracticeProgramForm22'), flex: 1, sortable: false },
        { field: 'date', headerName: t('Generel06'), flex: 1, sortable: false },
    ]

    // row clicked. Show DialogDownloadSendBoth so user can work with practice
    const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
        let action: ActionSetPractice = { type: 'setPractice', practice: mapPractice(state, state.allPractices.find(x => x.id === params.row.id)!) };
        dispatch(action);
        setOpenDialogDownloadSendBoth(true);
    };

    function handleChangeTeamFilter(event: SelectChangeEvent) {
        setSelectedTeamFilter(event.target.value as string)
    }

    // const handleChange = (event: SelectChangeEvent) => {
    //     setAge(event.target.value as string);
    //   };


    return (
        <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
            <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                {t('Practices00')}
            </Typography>
            <Divider sx={{ marginBottom: 3 }} />

            <FormControl fullWidth>
                <InputLabel id="quick-search-select-label">{t('PracticeProgramForm22')}</InputLabel>
                <Select
                    labelId="quick-search-select-label"
                    id="quick-search-select"
                    value={selectedTeamFilter}
                    label={t('PracticeProgramForm22')}
                    onChange={handleChangeTeamFilter}
                >
                    <MenuItem value="">
                        <em>{t('CRUDList06')}</em>
                    </MenuItem>

                    {
                        state.allTeams.map((item, index) => {
                            return (
                                <MenuItem key={index} value={item.attributes.title}>
                                    {item.attributes.title}
                                </MenuItem>
                            )
                        })
                    }
                </Select>
                <FormHelperText>{t('CRUDList11')}</FormHelperText>
            </FormControl>

            <DataGrid
                sx={{ marginTop: 3 }}
                columns={columns()}
                rows={
                    setPracticeNumber(state.allPractices
                        .filter(x => x.relationships.uid.data.id === state.user.data.id)
                        .map(x => mapPractice(state, x))
                        .filter(x => !selectedTeamFilter || x.team.attributes.title === selectedTeamFilter)
                        .sort(compareFNTeamNamePracticeDate)
                        .map(x => {
                            return {
                                id: x.practiceID,
                                pnum: 1,
                                team: x.team.attributes.title,
                                date: new Date(x.date).toLocaleString(state.user.locale.substring(0, 2), { dateStyle: 'short', timeStyle: 'short' }),
                            }
                        })
                    )
                }
                columnVisibilityModel={{ id: false }}
                disableColumnMenu
                onRowClick={handleRowClick}
            />
            <DialogDownloadSendBoth
                open={openDialogDownloadSendBoth}
                onClose={() => setOpenDialogDownloadSendBoth(false)}
            />
        </Box >
    )
}