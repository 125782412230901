import { Fragment, useContext, useState } from 'react';
import { Context } from '../App';
import log from "../misc/Logger";
import { TypeContext, DrupalEntity, ActionSetPractice } from '../misc/Types';
import { formatLanguage, getActionSetConfirm, mapPractice } from '../misc/Functions';
import { Card, Typography, IconButton, useTheme, Button, Box} from '@mui/material'; // Import Select and MenuItem
import { useTranslation } from 'react-i18next';
import Grid2 from '@mui/material/Unstable_Grid2';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DialogDownloadSendBoth from './DialogDownloadSendBoth';
import Badge from '@mui/material/Badge';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/system";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        height: 30,
        width: 90,
        padding: '10 10px',
        '&:not(:first-of-type)': {
            borderRadius: 5,
        },
        '&:first-of-type': {
            borderRadius: 5,
        },
        '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            color: theme.palette.text.primary,
            textTransform: 'none'
        },
        '&:not(.Mui-selected)': {
            color: 'darkgrey',
            textTransform: 'none'
        },
    },
}));

export default function PracticesCalendar() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('PracticesCalendar');
    const theme = useTheme();

    const [selectedDate, setSelectedDate] = useState(new Date()); // date selected in calendar
    const [openDialogDownloadSendBoth, setOpenDialogDownloadSendBoth] = useState(false);
    const [viewMode, setViewMode] = useState<'week' | 'month'>('week');

    // Change selected date in calendar
    function handleDayChange(day: Date) {
        setSelectedDate(new Date(day));
    }

    function _handlePracticeClick(practiceIDSelected: string) {
        let action: ActionSetPractice = { type: 'setPractice', practice: mapPractice(state, state.allPractices.find(x => x.id === practiceIDSelected)!) };
        dispatch(action);
        setOpenDialogDownloadSendBoth(true);
    }

    // Select practice. Must confirm if practice data dirty
    function handlePracticeClick(practiceIDSelected: string) {
        // If practice is dirty the warning before select new practice
        if (state.curPractice.dirty) {
            dispatch(getActionSetConfirm(t("Generel19"), 'OK', () => {
                _handlePracticeClick(practiceIDSelected)
            }));
        } else {
            _handlePracticeClick(practiceIDSelected)
        }
    }

    function renderDays(startDate: Date, endDate: Date) {
        // console.log(state.allPractices.length, state.allTeams.length, state.allUsers.length, 'renderDays()')
        const days = [];
        // get all practice dates
        const practiceDates = state.allPractices
            .filter(z => z.relationships.uid.data.id === state.user.data.id) // remember that clubadmin has access to club members practices but they should not be shown
            .map(x => mapPractice(state, x).date)

        const weekDays = [];
        const tempDate = new Date();
        tempDate.setDate(tempDate.getDate() - tempDate.getDay() + 1); // Set to Monday of the current week
        for (let i = 0; i < 7; i++) {
            weekDays.push(tempDate.toLocaleDateString(formatLanguage(state.user.locale), { weekday: 'short' }));
            tempDate.setDate(tempDate.getDate() + 1);
        }

        days.push(
            <Box key="weekdays" sx={{ display: 'flex', justifyContent: 'center' }}>
                {weekDays.map((day, index) => (
                    <Box key={index} sx={{ display: 'inline-block', textAlign: 'center', m: 0.5, width: 35, height: 35, lineHeight: '35px' }}>
                        {day}
                    </Box>
                ))}
            </Box>
        );

        let currentWeek = [];
        let dayOfWeek = startDate.getDay() === 0 ? 6 : startDate.getDay() - 1;

        for (let i = 0; i < dayOfWeek; i++) {
            currentWeek.push(<Box key={`empty-${i}`} sx={{ display: 'inline-block', textAlign: 'center', m: 0.5, width: 35, height: 35 }}></Box>);
        }

        for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
            const currentDay = new Date(day);
            // const practicesCount = practiceDates.filter(date => date === currentDay).length;
            const practicesCount = practiceDates.filter(date => new Date(date).toDateString() === currentDay.toDateString()).length;
            currentWeek.push(
                <Box key={currentDay.getDate()} sx={{ display: 'inline-block', textAlign: 'center', m: 0.5 }}>
                    <Badge
                        badgeContent={practicesCount}
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: '#DD6F20',
                                color: 'white',
                            },
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Box
                            sx={{
                                width: 35,
                                height: 35,
                                lineHeight: '35px',
                                borderRadius: '50%',
                                backgroundColor: currentDay.toDateString() === selectedDate.toDateString() ? theme.palette.primary.main : theme.palette.action.selected,
                                color: currentDay.toDateString() === selectedDate.toDateString() ? theme.palette.background.default : theme.palette.text.primary,
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                    border: '1px solid #ccc', // border on hover
                                },
                            }}
                            onClick={() => handleDayChange(currentDay)}
                        >
                            {currentDay.getDate()}
                        </Box>
                    </Badge>
                </Box>
            );

            if (currentDay.getDay() === 0 || day.getTime() === endDate.getTime()) {
                if (day.getTime() === endDate.getTime() && currentDay.getDay() !== 0) {
                    for (let i = currentDay.getDay(); i < 7; i++) {
                        currentWeek.push(<Box key={`empty-end-${i}`} sx={{ display: 'inline-block', textAlign: 'center', m: 0.5, width: 35, height: 35 }}></Box>);
                    }
                }
                days.push(<Box key={currentDay.toDateString()} sx={{ display: 'flex', justifyContent: 'center' }}>{currentWeek}</Box>);
                currentWeek = [];
            }
        }
        return days;
    }

    function renderWeekDays() {
        const startOfWeek = new Date(selectedDate);
        startOfWeek.setDate(startOfWeek.getDate() - (startOfWeek.getDay() === 0 ? 6 : startOfWeek.getDay() - 1));
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6);
        return renderDays(startOfWeek, endOfWeek);
    }

    function renderMonthDays() {
        const startOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
        const endOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
        return renderDays(startOfMonth, endOfMonth);
    }

    function CalendarStructure() {
        const getMonthTitle = () => {
            if (viewMode === 'week') {
                const startOfWeek = new Date(selectedDate);
                startOfWeek.setDate(startOfWeek.getDate() - (startOfWeek.getDay() === 0 ? 6 : startOfWeek.getDay() - 1));
                const endOfWeek = new Date(startOfWeek);
                endOfWeek.setDate(endOfWeek.getDate() + 6);

                const startMonth = startOfWeek.toLocaleDateString(formatLanguage(state.user.locale), { month: 'long', year: 'numeric' });
                const endMonth = endOfWeek.toLocaleDateString(formatLanguage(state.user.locale), { month: 'long', year: 'numeric' });

                return startMonth === endMonth ? startMonth : `${startMonth}/${endMonth}`;
            } else {
                return selectedDate.toLocaleDateString(formatLanguage(state.user.locale), { month: 'long', year: 'numeric' });
            }
        };

        return (
            <Fragment>
                <Grid2 container xs={12} sx={{ backgroundColor: '#7bb5ee', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography style={{ fontFamily: 'PT Sans, sans-serif', color: 'white', fontWeight: 'bold', fontSize: '18px', paddingTop: '4px', paddingLeft: '8px', paddingRight: '8px', textAlign: 'center' }}>
                        {t('PracticesCalendar00')}
                    </Typography>
                    <Typography style={{ fontFamily: 'PT Sans, sans-serif', color: '#f9f9f9', fontSize: '15px', paddingLeft: '8px', textAlign: 'center', paddingBottom: '4px' }}>
                        {getMonthTitle()}
                    </Typography>
                </Grid2>

                <Grid2 container xs={12} sx={{ justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                    <IconButton size="small" onClick={() => handleDayChange(new Date(selectedDate.setDate(selectedDate.getDate() - (viewMode === 'week' ? 7 : 30))))}>
                        <ArrowBackIcon />
                    </IconButton>

                    <StyledToggleButtonGroup
                        value={viewMode}
                        exclusive
                        onChange={(_event, newViewMode) => {
                            if (newViewMode !== null) {
                                setViewMode(newViewMode);
                            }
                        }}
                        aria-label="toggle view mode"
                    >
                        <ToggleButton value="week" aria-label="week view">
                            {t('PracticesCalendar01')}
                        </ToggleButton>
                        <ToggleButton value="month" aria-label="month view">
                            {t('PracticesCalendar02')}
                        </ToggleButton>
                    </StyledToggleButtonGroup>

                    <IconButton size="small" onClick={() => handleDayChange(new Date(selectedDate.setDate(selectedDate.getDate() + (viewMode === 'week' ? 7 : 30))))}>
                        <ArrowForwardIcon />
                    </IconButton>
                </Grid2>
            </Fragment>
        );
    }

    return (
        <Fragment>

            {/* {selectTeam()} */}

            <Card elevation={0} style={{ marginTop: 20, border: `1px solid ${theme.palette.divider}` }}>

                {CalendarStructure()}

                <Box justifyContent="center" alignItems="center" display="flex">
                    <div>
                        {viewMode === 'week' ? renderWeekDays() : renderMonthDays()}
                    </div>
                </Box>

                {/* Show 'calendar is loading' or 'no practices for today' */}
                {
                    state.allPractices
                        .filter(z => z.relationships.uid.data.id === state.user.data.id) // remember that clubadmin has access to club members practices but they should not be shown
                        .map((x: DrupalEntity) => mapPractice(state, x))
                        .filter(y => y.date.toDateString() === new Date(selectedDate).toDateString()).length === 0 && (
                        <Typography textAlign={'center'} sx={{ fontSize: { xs: 14, sm: 17 }, fontFamily: 'PT Sans, sans-serif', color: "grey" }} marginTop={1} marginBottom={1}>
                            {
                                // No practices to show. Because there are no practices today or because we are still loading?
                                state.practicesRetrieved ? t("PracticesCalendar04") : t("PracticesCalendar03")
                            }
                        </Typography>
                    )
                }

                {/* Show list of practices for day selected in calendar */}
                {
                    state.allPractices
                        .filter(z => z.relationships.uid.data.id === state.user.data.id) // remember that clubadmin has access to club members practices but they should not be shown
                        .map((x: DrupalEntity) => mapPractice(state, x))
                        .filter(y => y.date.toDateString() === new Date(selectedDate).toDateString())
                        .map((item, index) => (
                            <Box padding={1} key={index}>
                                <Button
                                    fullWidth
                                    sx={{
                                        textTransform: 'none',
                                        color: theme.palette.text.primary,
                                        fontFamily: 'PT Sans, sans-serif',
                                        backgroundColor: theme.palette.action.disabledBackground,
                                        marginTop: index === 0 ? '10px' : '0px', // MARGIN TOP ONLY IN THE FIRST BUTTON
                                        transition: 'border 0.3s', // SMALL TRANSITION FOR THE HOVER
                                        border: '1px solid transparent', // TRANSPARENT BORDER TO MAKE IT ALL LOOK EQUAL IN SIZE
                                        '&:hover': {
                                            backgroundColor: theme.palette.action.disabledBackground, // BACKGROUND ON HOVER
                                            border: '1px solid #7bb5ee', // 2PX BORDER ON HOVER
                                        },
                                    }}
                                    onClick={() => handlePracticeClick(item.practiceID || '')}
                                >
                                    <div>
                                        <strong>{item.team?.attributes.title}</strong>
                                        <div>
                                            {`${new Date(item.date).toLocaleDateString(formatLanguage(state.user.locale), { weekday: 'long' }).replace(/^\w/, c => c.toUpperCase())} ${t('PracticesCalendar05')} ${new Date(item.date).toLocaleTimeString(formatLanguage(state.user.locale), { hour: '2-digit', minute: '2-digit' })}`}
                                        </div>
                                    </div>
                                </Button>
                            </Box>
                        ))

                }
            </Card>

            <DialogDownloadSendBoth
                open={openDialogDownloadSendBoth}
                onClose={() => setOpenDialogDownloadSendBoth(false)}
            />
        </Fragment>
    )
}