import { Card, ListItemButton, ListItemIcon, Snackbar, Typography } from '@mui/material'
import { Fragment, useContext, useState } from 'react'
import { Context } from '../App';
import { TypeContext, UserType } from '../misc/Types';
import { useTranslation } from 'react-i18next';
import { ContentCopy, Info } from '@mui/icons-material';
import { getActionSetConfirm, icbControllerGenerel02 } from '../misc/Functions';
import log from "../misc/Logger";

export default function CopyToClipboardButton() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const [open, setOpen] = useState(false)

    return (
        <Fragment>
            {
                state.user.data.attributes.field_user_type === UserType.clubadmin &&
                <Fragment>
                    <Card elevation={0} style={{ marginTop: 20, backgroundColor: 'white', border: '1px solid #15468f', height: '35px', display: 'flex', alignItems: 'center' }}>
                        {/* <ListItemButton onClick={handleClick} style={{ flexGrow: 1 }}> */}
                        <ListItemButton
                            aria-label='copytoclipboard_copy_clubs_code'
                            style={{ flexGrow: 1 }}
                            onClick={async () => {
                                setOpen(true)
                                const resp = await icbControllerGenerel02(state, { "opr": "get_club_sha" })
                                if (!resp.ok) {
                                    dispatch(getActionSetConfirm(resp.error))
                                    return
                                }
                                const club_sha = resp.club_sha || 'default club code'
                                // on Mobile Sarafi this call for secirity reasons has to be performed from within the onClick context - see https://stackoverflow.com/questions/57868405/safari-clipboard-error-typeerror-undefined-is-not-an-object/72679775#72679775
                                // also, see this about using timeout: https://stackoverflow.com/questions/62327358/javascript-clipboard-api-safari-ios-notallowederror-message
                                setTimeout(() => {
                                    navigator.clipboard.writeText(club_sha)
                                        .then(() => { log.info(`copied to clipboard`) })
                                        .catch((error) => { log.error(`could not copy to clipboard, error: ${error}`) })
                                }, 0)
                            }}
                        >
                            <ListItemIcon style={{ color: '#00398F' }}>
                                <ContentCopy /> {/* replace with the icon you want to use */}
                            </ListItemIcon>
                            <Typography fontSize={12} style={{ fontWeight: 'bold', color: '#00398F', fontFamily: 'PT Sans, sans-serif' }}>
                                {t('CopyToClipboardButton01')}
                            </Typography>
                            <Snackbar
                                open={open}
                                onClose={() => setOpen(false)}
                                autoHideDuration={2000}
                                message={t('CopyToClipboardButton00')}
                            />
                        </ListItemButton>
                        <ListItemButton style={{ color: '#00398F', fontSize: '10px', flexGrow: 0, justifyContent: 'flex-end' }}>
                            <Info /> {/* replace with the icon you want to use */}
                            {t('CopyToClipboardButton02')}
                        </ListItemButton>
                    </Card>
                </Fragment>
            }
        </Fragment>
    )
}
