import { useContext, useEffect, useState } from "react";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { Context } from "../App";
import { getActionSetConfirm, icbControllerGenerel02 } from "../misc/Functions";
import { OperationMode, TypeContext } from "../misc/Types";
import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

export default function MyPlaybooks() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const navigate = useNavigate();
    const { t } = useTranslation();
    log.debug('Playbooks');
    const theme = useTheme();

    const [rows, setRows] = useState(getRows()) // get rows from back end initailly

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    // get rows from back end initailly
    useEffect(() => {
        setRows(getRows())
    }, [state.allPlaybooks])

    // get rows from back end
    function getRows() {
        return state.allPlaybooks.map((item) => ({ id: item.id, playbookName: item.attributes.title }))
    }

    function columns(): GridColDef[] {
        return [
            { field: 'id', headerName: 'ID', width: 10 },
            { field: 'playbookName', headerName: t('MyPlaybooks00'), flex: 1, editable: true, },
            {
                field: 'actions',
                type: 'actions',
                getActions: (params: GridRowParams) => [
                    <GridActionsCellItem onClick={() => handleDelete(params)} label="Delete" showInMenu />,
                ]
            }
        ]
    }

    // delete playbook from back end
    async function handleDelete(row: GridRowParams) {
        const resp = await icbControllerGenerel02(state, {
            opr: "delete_node",
            nid: row.id,
        })
        if (resp.ok)
            dispatch({ type: 'delContentEntity', contentType: 'node--playbook', id: row.id })
        else
            dispatch(getActionSetConfirm(resp.error));
    }

    return (
        <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
            <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                {t('MyPlaybooks01')}
            </Typography>
            <Divider sx={{ marginBottom: 3 }} />

            <Button
                variant="outlined"
                size="small"
                sx={{ marginBottom: 3 }}
                onClick={() => {
                    dispatch({ type: 'setOperationMode', operationMode: OperationMode.play });
                    dispatch({ type: 'showPracticeProgram', show: true });
                    navigate('/icbplays');
                }}
            >
                {t('ICBAppBar14')}
            </Button>

            <DataGrid
                rows={rows}
                columns={columns()}
                columnVisibilityModel={{ id: false }}
                disableColumnMenu
            />
        </Box>
    )
}