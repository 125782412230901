import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Context } from "../App";
import { nodeCRUD } from "../misc/Functions";
import { CRUD, DataGridActions, DataGridUpdate, DrupalEntity, TypeContext, UserType } from "../misc/Types";
import CRUDList from "./CRUDList";
import { Box, Divider, Typography, useTheme } from "@mui/material";

export default function Groups() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const theme = useTheme();
    
    // Only handle groups that come from Drupal and not the Total group that is not in Drupal
    const getRows = state.allGroups.filter(x => x.id).map((item) => ({
        id: item.id,
        nid: item.attributes.drupal_internal__nid,
        group: item.attributes.title,
        // userCreatedGroup: item.relationships.uid.data.id === state.user.data.id,
    }))

    const [rows, setRows] = useState(getRows)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    // Refresh/F5 - wait for all groups available
    useEffect(() => {
        setRows(getRows)
    }, [state.allGroups])

    const columns = () => [
        { field: 'id', headerName: 'ID', width: 10 },
        { field: 'group', headerName: 'Group', flex: 1, editable: true, },
    ]

    // processRowUpdate is called after rows are moved up/down, values in row updated, row is deleted
    function processRowUpdate() {
        return (dataGridUpdate: DataGridUpdate) =>
            new Promise((resolve, reject) => {
                if (dataGridUpdate.newRow) {
                    // Add or edit group
                    if (state.allGroups.find(x => x.attributes.title === dataGridUpdate.newRow!.group)) {
                        // Group name already exists
                        reject(new Error('Group name already exists'));
                        return
                    } else if (state.allGroups.find(x => x.id === dataGridUpdate.newRow!.id && x.relationships.uid.data.id !== state.user.data.id)) {
                        // Edit group that user did not create
                        reject(new Error(t('AlertMsg37')))
                        return
                    } else {
                        // Create or edit group
                        if (!dataGridUpdate.newRow!.group) {
                            reject(new Error('Please enter group name'))
                            return
                        }
                        let group = state.allGroups.find(x => x.id === dataGridUpdate.newRow!.id)
                        if (group) {
                            // Edit group
                            let groupLocal = JSON.parse(JSON.stringify(group))
                            groupLocal.attributes.title = dataGridUpdate.newRow.group
                            nodeCRUD(state, dispatch, CRUD.Update, groupLocal);
                        } else {
                            // Create group
                            group = {
                                "type": "node--group",
                                "attributes": {
                                    "title": dataGridUpdate.newRow.group,
                                    "field_initials": '?',
                                },
                            }
                            nodeCRUD(state, dispatch, CRUD.Create, group);
                        }
                    }
                } else if (dataGridUpdate.delete) {
                    // Delete group
                    const group = state.allGroups.find(x => x.id === dataGridUpdate.id)
                    // Check user created group and not ICB group
                    if (group!.relationships.uid.data.id === state.user.data.id)
                        nodeCRUD(state, dispatch, CRUD.Delete, group!)
                    else {
                        reject(new Error(t('AlertMsg37')))
                        return
                    }
                } else {
                    // Reorder groups
                    // Get current user data
                    const userData: DrupalEntity = JSON.parse(JSON.stringify(state.user.data));
                    userData.attributes.field_groups_sort = dataGridUpdate.rows.map((item) => item.nid).toString()
                    nodeCRUD(state, dispatch, CRUD.Update, userData)
                }
                resolve(dataGridUpdate.rows)
            })
    }

    return (
        <Fragment>
            <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
                <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                    {t('Groups00')}
                </Typography>
                <Divider />
            </Box>
            <CRUDList
                rows={rows}
                columns={columns()}
                actions={[DataGridActions.save, DataGridActions.cancel, DataGridActions.delete, DataGridActions.edit, DataGridActions.down, DataGridActions.up]}
                processRowUpdate={processRowUpdate}
                labelAddRow={[UserType.clubadmin].includes(state.user.data.attributes.field_user_type) ?  t('Groups01') : undefined}
                gridColumnVisibilityModel={{ id: false }}
                // dataColumn="group"
                columnFocusOnNew="group"
                deleteWarning={t('Groups02')}
            />
        </Fragment>
    )
}