import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { ThemeContextProvider } from './theme/ThemeContextProvider.tsx'

ReactDOM.createRoot(document.getElementById('root')!).render(

  <ThemeContextProvider>
    {/* <React.StrictMode> */}
    <BrowserRouter basename={import.meta.env.BASE_URL}>
      <App />
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </ThemeContextProvider>
)
