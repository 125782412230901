import { Button, Typography, Grid } from '@mui/material';
import { useContext } from 'react';
import { Context } from '../../App';
import { TypeContext } from '../../misc/Types';
import SportsBasketballRoundedIcon from '@mui/icons-material/SportsBasketballRounded';


interface ActionButtonsProps {
    selectedPlayerId: number | null;
    createLine: (type: 'straight' | 'zigzag' | 'dashed' | 'bar') => void;
    handleCreateNewFrame: () => void;
    setIsModalOpen: (isOpen: boolean) => void;
    handleDeleteLine: () => void;
    handleToggleBall: () => void;
    handleSavePlay: () => void;
    // handleSendPlay: () => void;
}

export default function ActionButtons({
    // selectedPlayerId,
    createLine,
    //handleCreateNewFrame,
    //setIsModalOpen,
    handleDeleteLine,
    handleToggleBall,
    //handleSavePlay,
    // handleSendPlay,
}: ActionButtonsProps) {
    const { state } = useContext(Context) as TypeContext;

    return (
        <>
            {!state.portrait ? (
                <div style={{ marginBottom: '10px' }}>
                    <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '10px', textAlign: 'center' }}>
                        Actions
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{ textTransform: 'none', borderRadius: '5px' }}
                                onClick={() => createLine('straight')}
                            >
                                Run
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{ textTransform: 'none', borderRadius: '5px' }}
                                onClick={() => createLine('zigzag')}
                            >
                                Dribble
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{ textTransform: 'none', borderRadius: '5px' }}
                                onClick={() => createLine('dashed')}
                            >
                                Pass
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{ textTransform: 'none', borderRadius: '5px' }}
                                onClick={() => createLine('bar')}
                            >
                                Screen
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                color="secondary"
                                sx={{ textTransform: 'none', borderRadius: '5px' }}
                                onClick={handleToggleBall}
                            >
                                Ball
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="outlined"
                                color="error"
                                fullWidth
                                sx={{ textTransform: 'none', borderRadius: '5px' }}
                                onClick={handleDeleteLine}
                            // disabled={selectedPlayerId === null}
                            >
                                Delete Line
                            </Button>
                        </Grid>
                        {/* <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="error"
                                fullWidth
                                sx={{ textTransform: 'none', borderRadius: '5px' }}
                                onClick={handleSavePlay}
                            >
                                Save Play
                            </Button>
                        </Grid> */}
                    </Grid>
                </div>
            ) :

                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <Button
                            variant="outlined"
                            size="small"
                            fullWidth
                            sx={{ textTransform: 'none', borderRadius: '5px' }}
                            onClick={() => createLine('straight')}
                        >
                            Run
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant="outlined"
                            size="small"
                            fullWidth
                            sx={{ textTransform: 'none', borderRadius: '5px' }}
                            onClick={() => createLine('zigzag')}
                        >
                            Dribble
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant="outlined"
                            size="small"
                            fullWidth
                            sx={{ textTransform: 'none', borderRadius: '5px' }}
                            onClick={() => createLine('dashed')}
                        >
                            Pass
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant="outlined"
                            size="small"
                            fullWidth
                            sx={{ textTransform: 'none', borderRadius: '5px' }}
                            onClick={() => createLine('bar')}
                        >
                            Screen
                        </Button>
                    </Grid>
                    <Grid item xs={8}>
                        <Button
                            variant="contained"
                            size="small"
                            fullWidth
                            color="secondary"
                            sx={{ textTransform: 'none', borderRadius: '5px' }}
                            onClick={handleToggleBall}
                        >
                            <SportsBasketballRoundedIcon style={{ fontSize: '16px' }} />
                            Ball
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="outlined"
                            color="error"
                            size="small"
                            fullWidth
                            sx={{ textTransform: 'none', borderRadius: '5px' }}
                            onClick={handleDeleteLine}
                        // disabled={selectedPlayerId === null}
                        >
                            Delete Line
                        </Button>
                    </Grid>
                    {/* <Grid item xs={6}>
                <Button
                    variant="contained"
                    color="error"
                    fullWidth
                    sx={{ textTransform: 'none', borderRadius: '5px' }}
                    onClick={handleSavePlay}
                >
                    Save Play
                </Button>
            </Grid> */}

                </Grid>



            }
        </>
    );
}

//ORIGINALLY FROM PLAYBUILDER.TSX

// const handleSend = async () => {
//   // Generate the HTML content
//   const htmlContent = `
//     <!DOCTYPE html>
//     <html>
//     <head>
//       <meta charset="UTF-8">
//       <title>SVG Animation</title>
//       <style>
//         /* Optional: Add some basic styling */
//         #controls {
//           text-align: center;
//           margin-top: 10px;
//         }
//         #play-pause {
//           padding: 5px 10px;
//         }
//         #progress-bar {
//           width: 80%;
//           margin-top: 10px;
//         }
//       </style>
//     </head>
//     <body>
//       ${generateSVGContent(frames)}

//       <!-- Controls -->
//       <div id="controls">
//         <button id="play-pause">Pause</button>
//         <input type="range" id="progress-bar" min="0" max="1000" value="0">
//       </div>

//       <script>
//         ${generateAnimationScript(frames)}
//       </script>
//     </body>
//     </html>
//   `;

//   const resp = await icbControllerGenerel02(state, {
//     opr: 'send_play',
//     receivers: 'tps@netmaster.dk',
//     emailBody00: htmlContent,
//     emailBody01: '',
//     emailSubject: t('here is your play'),
//     playID: location.state.id,
//     playName: location.state.attributes.title,
//   });

//   if (resp.ok) {
//     dispatch(getActionSetConfirm(`${BACKEND}/${resp.url} uid/pwd; ${resp.htaccess_uid}/${resp.htaccess_pwd}`));
//   } else {
//     dispatch(getActionSetConfirm(resp.error));
//   }
// };
