import React, { useState, useRef, useEffect, useContext } from 'react';
import { BasketballHalfCourt } from './BasketballHalfCourt.svg';
import { BasketballFullCourt } from './BasketballFullCourt.svg';
import ActionButtons from './ActionButtons';
import { Button, Dialog, Grid, IconButton, Typography, useTheme, Box } from '@mui/material';
import { Context } from '../../App';
import { CRUD, DrupalEntity, TypeContext } from '../../misc/Types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import log from '../../misc/Logger';
import { getActionSetConfirm, nodeCRUD } from '../../misc/Functions';
import ReactDOMServer from 'react-dom/server';
import DialogPlayAnimation from './DialogPlayAnimation';
import PhasesSectionPlayBuilder from './PhasesSectionPlayBuilder';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { DRAGGINGSTATE, FRAMEINIT } from '../../misc/Constants';

// Interfaces
export interface Player {
  id: number;
  x: number;
  y: number;
  number: number;
  hasBall: boolean;
}

export interface Play {
  frames: Frame[];
  currentFrameIndex: number;
  dragging: DraggingState;
  selectedPlayerId: number | null;
  isModalOpen: boolean;
  nextPlayerId: number;
  nextLineId: number;
  courtType: 'halfcourt' | 'fullcourt';
}

export interface Line {
  id: number;
  fromId: number;
  toId?: number;
  type: 'straight' | 'zigzag' | 'dashed' | 'bar';
  toX: number;
  toY: number;
  controlX: number;
  controlY: number;
}

export interface Frame {
  id: number;
  players: Player[];
  lines: Line[];
}

interface DraggingState {
  isDragging: boolean;
  type: 'player' | 'end' | 'control' | null;
  id: number | null;
}

interface PropsDialogPlayBuilder {
  open: boolean;
  onClose: (play: PlayDetails | null) => void;
}

export interface PlayDetails {
  frames: Frame[],
  currentFrameIndex: number,
  dragging: DraggingState,
  selectedPlayerId: number | null,
  courtType: 'halfcourt' | 'fullcourt',
};

export const courtDimensions = {
  halfcourt: { width: 542, height: 455 },
  fullcourt: { width: 910, height: 550 },
};

// Helper functions
export const getOffsetPoint = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  offset: number
) => {
  const dx = x2 - x1;
  const dy = y2 - y1;
  const length = Math.hypot(dx, dy);
  if (length === 0) return { x: x1, y: y1 };
  const ratio = offset / length;
  return { x: x1 + dx * ratio, y: y1 + dy * ratio };
};

// Smooth line for curves
export const approximateBezierLength = (
  from: { x: number; y: number },
  control: { x: number; y: number },
  to: { x: number; y: number }
) => {
  const steps = 10;
  let length = 0;
  let prevPoint = from;

  for (let i = 1; i <= steps; i++) {
    const t = i / steps;
    const xt =
      (1 - t) ** 2 * from.x + 2 * (1 - t) * t * control.x + t ** 2 * to.x;
    const yt =
      (1 - t) ** 2 * from.y + 2 * (1 - t) * t * control.y + t ** 2 * to.y;

    length += Math.hypot(xt - prevPoint.x, yt - prevPoint.y);
    prevPoint = { x: xt, y: yt };
  }

  return length;
};

export const createZigzagPath = (
  from: { x: number; y: number },
  control: { x: number; y: number },
  to: { x: number; y: number },
  amplitude = 3,
  oscillationsPer100px = 12,
  finalSegmentLength = 10
) => {
  // Primero, aproximamos la longitud total de la curva
  const length = approximateBezierLength(from, control, to);
  const totalOscillations = Math.max(
    2,
    Math.round((length / 100) * oscillationsPer100px)
  );

  const steps = 1000;
  const dt = 1 / steps;

  const cumulativeLengths = [0];
  let prevX = from.x;
  let prevY = from.y;
  let totalLength = 0;

  // Calculamos las longitudes acumuladas y los puntos a lo largo de la curva
  for (let i = 1; i <= steps; i++) {
    const t = i * dt;
    const xt =
      (1 - t) ** 2 * from.x + 2 * (1 - t) * t * control.x + t ** 2 * to.x;
    const yt =
      (1 - t) ** 2 * from.y + 2 * (1 - t) * t * control.y + t ** 2 * to.y;

    const dx = xt - prevX;
    const dy = yt - prevY;
    const ds = Math.hypot(dx, dy);

    totalLength += ds;
    cumulativeLengths.push(totalLength);

    prevX = xt;
    prevY = yt;
  }

  // Generamos puntos equiespaciados a lo largo de la curva
  const path = [];
  path.push(`M ${from.x} ${from.y}`);

  const numPoints = steps;
  const desiredLengths = [];
  for (let i = 1; i <= numPoints; i++) {
    desiredLengths.push((i / numPoints) * totalLength);
  }

  for (let i = 0; i < desiredLengths.length; i++) {
    const s = desiredLengths[i];

    // Encontramos el índice donde la longitud acumulada es mayor o igual a s
    let j = 0;
    while (j < cumulativeLengths.length && cumulativeLengths[j] < s) {
      j++;
    }
    if (j === 0) {
      j = 1;
    }

    // Interpolación lineal para encontrar t
    const s0 = cumulativeLengths[j - 1];
    const s1 = cumulativeLengths[j];
    const t0 = (j - 1) * dt;
    const t1 = j * dt;

    const t = t0 + ((s - s0) / (s1 - s0)) * (t1 - t0);

    // Calculamos el punto y la derivada en t
    const xt =
      (1 - t) ** 2 * from.x + 2 * (1 - t) * t * control.x + t ** 2 * to.x;
    const yt =
      (1 - t) ** 2 * from.y + 2 * (1 - t) * t * control.y + t ** 2 * to.y;

    const dxdt =
      2 * (1 - t) * (control.x - from.x) + 2 * t * (to.x - control.x);
    const dydt =
      2 * (1 - t) * (control.y - from.y) + 2 * t * (to.y - control.y);

    const len = Math.hypot(dxdt, dydt);
    const nx = -dydt / len;
    const ny = dxdt / len;

    const frequency = (totalOscillations * Math.PI) / totalLength;
    const offset = amplitude * Math.sin(s * frequency);

    const offsetX = xt + offset * nx;
    const offsetY = yt + offset * ny;

    path.push(`L ${offsetX} ${offsetY}`);
  }

  // Añadimos el segmento final
  const theta = Math.atan2(to.y - control.y, to.x - control.x);
  const finalX = to.x + finalSegmentLength * Math.cos(theta);
  const finalY = to.y + finalSegmentLength * Math.sin(theta);
  path.push(`L ${finalX} ${finalY}`);

  return path.join(' ');
};

// Arrowhead and bar definitions
export const SvgDefs: React.FC = () => (
  <defs>
    <marker
      id="arrowhead"
      markerWidth="10"
      markerHeight="7"
      refX="6"
      refY="3.5"
      orient="auto"
    >
      <polygon points="0 0, 10 3.5, 0 7" fill="black" />
    </marker>
    <marker
      id="barEnd"
      markerWidth="1"
      markerHeight="16"
      refX="0.5"
      refY="8"
      orient="auto"
    >
      <rect x="0" y="0" width="1" height="16" fill="black" />
    </marker>
  </defs>
);

// MAIN COMPONENT
export default function DialogPlayBuilder(props: PropsDialogPlayBuilder) {
  const { state, dispatch } = useContext(Context) as TypeContext;
  const { t } = useTranslation();
  log.debug('DialogPlayBuilder');
  const theme = useTheme();

  const location = useLocation();

  // State to manage the court type
  const [courtType, setCourtType] = useState<'halfcourt' | 'fullcourt'>('halfcourt')

  // Court dimensions depending on the court type
  const MAX_SVG_WIDTH = courtType === 'halfcourt' ? 600 : 800;

  const { width: courtWidth, height: courtHeight } = courtDimensions[courtType];

  // Toggle to change the court type
  const handleCourtChange = (
    _event: React.MouseEvent<HTMLElement>,
    newCourtType: 'halfcourt' | 'fullcourt' | null
  ) => {
    if (newCourtType !== null) {
      setCourtType(newCourtType);
    }
  };

  const [frames, setFrames] = useState<Frame[]>([FRAMEINIT]);

  const [currentFrameIndex, setCurrentFrameIndex] = useState<number>(0);
  const [dragging, setDragging] = useState<DraggingState>(DRAGGINGSTATE);
  const [selectedPlayerId, setSelectedPlayerId] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [nextPlayerId,] = useState<number>(1);
  const [nextLineId, setNextLineId] = useState<number>(1);

  const svgRef = useRef<SVGSVGElement | null>(null);

  // Set play. Test we have play details in field_play_details
  useEffect(() => {
    const play: Play = location.state.attributes.field_play_details && JSON.parse(
      location.state.attributes.field_play_details
    );
    if (play) {
      setFrames(play.frames || frames);
      setCurrentFrameIndex(play.currentFrameIndex || 0);
      setDragging(
        play.dragging || {
          isDragging: false,
          type: null,
          id: null,
        }
      );
      setSelectedPlayerId(play.selectedPlayerId || null);
      setIsModalOpen(play.isModalOpen || false);
      setNextLineId(play.nextLineId || 1);
      setCourtType(play.courtType || 'halfcourt');
    } else {
      setFrames(frames);
      setCurrentFrameIndex(0);
      setDragging({
        isDragging: false,
        type: null,
        id: null,
      });
      setSelectedPlayerId(null);
      setIsModalOpen(false);
      setNextLineId(1);
      setCourtType('halfcourt');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!dragging.isDragging || !svgRef.current) return;

    const handleMove = (e: MouseEvent | TouchEvent) => {
      if (!svgRef.current) return;

      const { x: clientX, y: clientY } = getEventCoordinates(e);

      const svgPoint = svgRef.current.createSVGPoint();
      svgPoint.x = clientX;
      svgPoint.y = clientY;
      const cursorPoint = svgPoint.matrixTransform(
        svgRef.current.getScreenCTM()?.inverse()
      );

      const mouseX = cursorPoint.x;
      const mouseY = cursorPoint.y;

      setFrames((prevFrames) => {
        const updatedFrames = [...prevFrames];
        const currentFrame = { ...updatedFrames[currentFrameIndex] };

        if (dragging.type && dragging.id !== null) {
          if (dragging.type === 'player') {
            currentFrame.players = currentFrame.players.map((player) =>
              player.id === dragging.id
                ? { ...player, x: mouseX, y: mouseY }
                : player
            );
          } else {
            currentFrame.lines = currentFrame.lines.map((line) => {
              if (line.id === dragging.id) {
                if (dragging.type === 'end') {
                  // Agregar condición para verificar si la línea es 'dashed'
                  if (line.type === 'dashed') {
                    let newToX = mouseX;
                    let newToY = mouseY;
                    let newToId = undefined;

                    const targetPlayer = currentFrame.players.find(
                      (player) =>
                        player.id !== line.fromId &&
                        Math.hypot(player.x - mouseX, player.y - mouseY) < 20
                    );

                    if (targetPlayer) {
                      newToX = targetPlayer.x;
                      newToY = targetPlayer.y;
                      newToId = targetPlayer.id;
                    }

                    return {
                      ...line,
                      toX: newToX,
                      toY: newToY,
                      toId: newToId,
                    };
                  } else {
                    // Si la línea no es 'dashed', no conectar automáticamente
                    return { ...line, toX: mouseX, toY: mouseY };
                  }
                } else {
                  return { ...line, controlX: mouseX, controlY: mouseY };
                }
              }
              return line;
            });
          }
        }

        updatedFrames[currentFrameIndex] = currentFrame;
        return updatedFrames;
      });
    };

    const handleEnd = () => {
      setDragging({ isDragging: false, type: null, id: null });
    };

    // Agregar ambos listeners de eventos
    window.addEventListener('mousemove', handleMove);
    window.addEventListener('mouseup', handleEnd);
    window.addEventListener('touchmove', handleMove, { passive: false });
    window.addEventListener('touchend', handleEnd);
    window.addEventListener('touchcancel', handleEnd);

    return () => {
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseup', handleEnd);
      window.removeEventListener('touchmove', handleMove);
      window.removeEventListener('touchend', handleEnd);
      window.removeEventListener('touchcancel', handleEnd);
    };
  }, [dragging, currentFrameIndex]);

  // Utility function to extract coordinates from mouse or touch events
  const getEventCoordinates = (e: MouseEvent | TouchEvent) => {
    if (e instanceof MouseEvent) {
      return { x: e.clientX, y: e.clientY };
    } else if (e instanceof TouchEvent) {
      const touch = e.touches[0] || e.changedTouches[0];
      return { x: touch.clientX, y: touch.clientY };
    }
    return { x: 0, y: 0 };
  };

  // Unified start handler for both mouse and touch
  const handleStart = (
    e: React.MouseEvent<SVGElement, MouseEvent> | React.TouchEvent<SVGElement>,
    type: 'player' | 'end' | 'control',
    id: number
  ) => {
    e.stopPropagation();

    setDragging({ isDragging: true, type, id });

    if (type === 'player') setSelectedPlayerId(id);
  };

  const createLine = (type: 'straight' | 'zigzag' | 'dashed' | 'bar') => {
    if (selectedPlayerId === null) {
      alert(t('PlayBuilder00'));
      return;
    }

    const currentFrame = frames[currentFrameIndex];
    const selectedPlayer = currentFrame.players.find(
      (p) => p.id === selectedPlayerId
    );

    if (!selectedPlayer) return;

    if ((type === 'zigzag' || type === 'dashed') && !selectedPlayer.hasBall) {
      alert(t('PlayBuilder01'));
      return;
    }

    const existingLine = currentFrame.lines.find(
      (line) => line.fromId === selectedPlayerId
    );
    if (existingLine) {
      alert(t('PlayBuilder02'));
      return;
    }

    const toX = selectedPlayer.x;
    const toY = selectedPlayer.y - 50;
    const controlX = selectedPlayer.x;
    const controlY = selectedPlayer.y - 25;

    const newLine: Line = {
      id: nextLineId,
      fromId: selectedPlayerId,
      type,
      toX,
      toY,
      controlX,
      controlY,
    };

    setNextLineId((prev) => prev + 1);

    setFrames((prevFrames) => {
      const updatedFrames = [...prevFrames];
      const currentFrame = { ...updatedFrames[currentFrameIndex] };
      currentFrame.lines = [...currentFrame.lines, newLine];
      updatedFrames[currentFrameIndex] = currentFrame;
      return updatedFrames;
    });
  };

  const handleCreateNewFrame = () => {
    const currentFrame = frames[currentFrameIndex];

    const updatedPlayers = currentFrame.players.map((player) => {
      const connectedLine = currentFrame.lines.find(
        (line) => line.fromId === player.id
      );

      if (connectedLine) {
        if (connectedLine.type === 'dashed') {
          if (player.hasBall) {
            const receiver = currentFrame.players.find(
              (p) => p.id === connectedLine.toId
            );
            if (receiver) {
              return { ...player, hasBall: false };
            }
          }
        } else {
          return {
            ...player,
            x: connectedLine.toX,
            y: connectedLine.toY,
          };
        }
      }
      return { ...player };
    });

    const newPlayers = updatedPlayers.map((player) => {
      const receivedBall = currentFrame.lines.some(
        (line) =>
          line.type === 'dashed' &&
          line.toId === player.id &&
          currentFrame.players.find((p) => p.id === line.fromId)?.hasBall
      );
      if (receivedBall) {
        return { ...player, hasBall: true };
      }
      return player;
    });

    const newFrame: Frame = {
      id: frames.length + 1,
      players: newPlayers,
      lines: [],
    };

    setFrames((prevFrames) => [...prevFrames, newFrame]);
    setCurrentFrameIndex(frames.length);
    const playerWithBall = newPlayers.find((player) => player.hasBall);
    if (playerWithBall) {
      setSelectedPlayerId(playerWithBall.id);
    } else {
      setSelectedPlayerId(null);
    }
  };

  const handleDeleteFrame = (index: number) => {
    if (frames.length === 1) {
      alert(t('PlayBuilder03'));
      return;
    }

    // Elimina el marco seleccionado
    const updatedFrames = frames.filter((_, i) => i !== index);

    // Reasigna los IDs para que sean consecutivos
    const reindexedFrames = updatedFrames.map((frame, i) => ({
      ...frame,
      id: i + 1,
    }));

    setFrames(reindexedFrames);

    // Actualiza el índice del marco actual si es necesario
    if (currentFrameIndex >= reindexedFrames.length) {
      setCurrentFrameIndex(reindexedFrames.length - 1);
    } else if (index < currentFrameIndex) {
      setCurrentFrameIndex(prevIndex => prevIndex - 1);
    }
  };

  const handleSavePlay = async () => {
    const play: any = {
      frames: frames,
      currentFrameIndex: currentFrameIndex,
      dragging: dragging,
      selectedPlayerId: selectedPlayerId,
      isModalOpen: isModalOpen,
      nextPlayerId: nextPlayerId,
      nextLineId: nextLineId,
      courtType: courtType,
    };
    const playNode: DrupalEntity = {
      type: 'node--play',
      id: location.state.id,
      attributes: {
        field_play_details: JSON.stringify(play),
      },
    };
    const resp = await nodeCRUD(state, dispatch, CRUD.Update, playNode);
    if (!resp.data) {
      dispatch(getActionSetConfirm(t(resp)));
    }
    // handleCloseCreatePlay();
  };

  const handleDeleteLine = () => {
    if (selectedPlayerId === null) {
      alert(t('PlayBuilder04'));
      return;
    }

    setFrames((prevFrames) => {
      const updatedFrames = [...prevFrames];
      const currentFrame = { ...updatedFrames[currentFrameIndex] };
      currentFrame.lines = currentFrame.lines.filter(
        (line) =>
          line.fromId !== selectedPlayerId && line.toId !== selectedPlayerId
      );
      updatedFrames[currentFrameIndex] = currentFrame;
      return updatedFrames;
    });
  };

  const handleToggleBall = () => {
    if (selectedPlayerId === null) {
      alert(t('PlayBuilder05'));
      return;
    }

    setFrames((prevFrames) => {
      const updatedFrames = [...prevFrames];
      const currentFrame = { ...updatedFrames[currentFrameIndex] };
      currentFrame.players = currentFrame.players.map((player) =>
        player.id === selectedPlayerId
          ? { ...player, hasBall: !player.hasBall }
          : player
      );
      updatedFrames[currentFrameIndex] = currentFrame;
      return updatedFrames;
    });
  };

  const handleSelectFrame = (index: number) => {
    setCurrentFrameIndex(index);
    setSelectedPlayerId(null);
    setDragging({ isDragging: false, type: null, id: null });
  };

  // Functions to generate SVG content and animation script
  const generateSVGContent = (framesData: Frame[]) => {
    // Use ReactDOMServer to render the SVG court
    const courtSVGContent = ReactDOMServer.renderToStaticMarkup(
      courtType === 'fullcourt' ? <BasketballFullCourt /> : <BasketballHalfCourt />
    );

    const allPlayerIds = Array.from(
      new Set(framesData.flatMap((frame) => frame.players.map((player) => player.id)))
    );

    const playersSVG = allPlayerIds
      .map((playerId) => {
        const firstAppearance = framesData.find((frame) =>
          frame.players.some((player) => player.id === playerId)
        );
        const player =
          firstAppearance &&
          firstAppearance.players.find((player) => player.id === playerId);

        if (!player) return '';

        return `
        <g id="player-${player.id}">
          ${player.hasBall
            ? `<circle cx="${player.x}" cy="${player.y}" r="12" stroke="black" stroke-width="2" fill="none" />`
            : ''
          }
          <text x="${player.x}" y="${player.y}" text-anchor="middle" alignment-baseline="middle" font-size="16" font-weight="bold" fill="black">${player.number}</text>
        </g>
        `;
      })
      .join('');

    const allLineIds = Array.from(
      new Set(framesData.flatMap((frame) => frame.lines.map((line) => line.id)))
    );

    const linesSVG = allLineIds
      .map((lineId) => {
        const firstAppearance = framesData.find((frame) =>
          frame.lines.some((line) => line.id === lineId)
        );
        const line =
          firstAppearance &&
          firstAppearance.lines.find((line) => line.id === lineId);

        if (!line) return '';

        const fromPlayer = firstAppearance.players.find(
          (p) => p.id === line.fromId
        );

        if (!fromPlayer) return '';

        const adjustedFrom = getOffsetPoint(
          fromPlayer.x,
          fromPlayer.y,
          line.toX,
          line.toY,
          0
        );
        const adjustedTo = getOffsetPoint(
          line.toX,
          line.toY,
          fromPlayer.x,
          fromPlayer.y,
          0
        );

        const pathD = generatePathD(line, adjustedFrom, adjustedTo);
        const strokeDasharray =
          line.type === 'dashed' ? 'stroke-dasharray="5,5"' : '';
        const markerEnd =
          line.type === 'bar'
            ? 'marker-end="url(#barEnd)"'
            : 'marker-end="url(#arrowhead)"';

        return `
        <path id="line-${line.id}" d="${pathD}" stroke="black" stroke-width="2" fill="none" style="display: none;" ${strokeDasharray} ${markerEnd} />
        `;
      })
      .join('');

    const markerDefinitions = `
      <defs>
        <marker
          id="arrowhead"
          markerWidth="10"
          markerHeight="7"
          refX="6"
          refY="3.5"
          orient="auto"
        >
          <polygon points="0 0, 10 3.5, 0 7" fill="black" />
        </marker>
        <marker
          id="barEnd"
          markerWidth="1"
          markerHeight="16"
          refX="0.5"
          refY="8"
          orient="auto"
        >
          <rect x="0" y="0" width="1" height="16" fill="black" />
        </marker>
      </defs>
    `;

    return `
      <svg id="animation-svg" viewBox="0 0 ${courtWidth} ${courtHeight}" preserveAspectRatio="xMidYMid meet">
        ${courtSVGContent}
        ${markerDefinitions}
        ${playersSVG}
        ${linesSVG}
      </svg>
    `;
  };

  // Generate the animation script for the html animation
  const generateAnimationScript = (framesData: Frame[]) => {
    // Return a string containing the JavaScript code for the animation
    return `
 const frames = ${JSON.stringify(framesData)};
      const totalFrames = frames.length - 1;
      const durationPerFrame = 2000; // 2 segundos por cuadro
      const totalDuration = totalFrames * durationPerFrame;
      let isPlaying = true;
      let currentTime = 0;
      let lastTimestamp = null;
      const players = {};
      const pathRefs = {};
      const movingBalls = {};

      // Recopilar todos los jugadores a través de los cuadros
      const playerIds = [...new Set(frames.flatMap(frame => frame.players.map(player => player.id)))];
      playerIds.forEach(playerId => {
        players[playerId] = document.getElementById('player-' + playerId);
      });

      // Recopilar todas las líneas a través de los cuadros
      const lineIds = [...new Set(frames.flatMap(frame => frame.lines.map(line => line.id)))];
      lineIds.forEach(lineId => {
        const pathElement = document.getElementById('line-' + lineId);
        if (pathElement) {
          pathRefs[lineId] = pathElement;
          // Inicialmente ocultar todas las líneas
          pathElement.style.display = 'none';
        }
      });

      // Controles
      const playPauseButton = document.getElementById('play-pause');
      const progressBar = document.getElementById('progress-bar');
      playPauseButton.addEventListener('click', () => {
        isPlaying = !isPlaying;
        if (isPlaying) {
          playPauseButton.textContent = 'Pausa';
          lastTimestamp = null;
          requestAnimationFrame(animate);
        } else {
          playPauseButton.textContent = 'Play';
        }
      });

      progressBar.addEventListener('input', (event) => {
        const value = parseFloat(event.target.value);
        currentTime = (value / 1000) * totalDuration;
        lastTimestamp = null;
        updateAnimation(currentTime);
      });

      function animate(timestamp) {
        if (!isPlaying) return;

        if (!lastTimestamp) lastTimestamp = timestamp;
        const delta = timestamp - lastTimestamp;
        lastTimestamp = timestamp;

        currentTime += delta;
        if (currentTime > totalDuration) {
          currentTime = totalDuration;
          isPlaying = false;
          playPauseButton.textContent = 'Play';
        }

        updateAnimation(currentTime);

        if (isPlaying) {
          requestAnimationFrame(animate);
        }
      }

      function updateAnimation(time) {
        // Actualizar la barra de progreso
        const progressValue = (time / totalDuration) * 1000;
        progressBar.value = progressValue;

        // Determinar el cuadro actual y el valor de t
        let frameIndex = Math.floor(time / durationPerFrame);
        if (frameIndex >= totalFrames) {
          frameIndex = totalFrames - 1;
        }
        const frameTime = time - frameIndex * durationPerFrame;
        const t = Math.min(frameTime / durationPerFrame, 1);

        const currentFrame = frames[frameIndex];
        const nextFrame = frames[frameIndex + 1];

        // Ocultar todas las líneas
        lineIds.forEach(lineId => {
          const pathElement = pathRefs[lineId];
          if (pathElement) {
            pathElement.style.display = 'none';
          }
        });

        // Mostrar líneas del cuadro actual
        currentFrame.lines.forEach(line => {
          const pathElement = pathRefs[line.id];
          if (pathElement) {
            pathElement.style.display = '';
            pathElement.style.opacity = '0.3'; // Establecer opacidad
          }
        });

        // Eliminar todas las bolas en movimiento
        Object.values(movingBalls).forEach(ball => {
          ball.remove();
        });
        for (const key in movingBalls) {
          delete movingBalls[key];
        }

        // Actualizar posiciones de los jugadores y estado del balón
        playerIds.forEach(playerId => {
          const playerCurrent = currentFrame.players.find(p => p.id === playerId);
          const playerNext = nextFrame.players.find(p => p.id === playerId);

          if (playerCurrent && playerNext) {
            // Verificar si hay una línea asociada con el movimiento del jugador
            const line = currentFrame.lines.find(line => line.fromId === playerId);

            if (line) {
              const pathElement = pathRefs[line.id];
              if (pathElement) {
                if (line.type === 'dashed' && playerCurrent.hasBall) {
                  // Manejar animación de pase
                  // Mover una bola a lo largo de la ruta
                  const totalLength = pathElement.getTotalLength();
                  const point = pathElement.getPointAtLength(t * totalLength);

                  // Si aún no hemos creado una bola para esta línea, crearla
                  if (!movingBalls[line.id]) {
                    const ballElement = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
                    ballElement.setAttribute('r', '12');
                    ballElement.setAttribute('stroke', 'black');
                    ballElement.setAttribute('stroke-width', '2');
                    ballElement.setAttribute('fill', 'none');
                    // Insertar el ballElement en el SVG
                    document.getElementById('animation-svg').appendChild(ballElement);
                    movingBalls[line.id] = ballElement;
                  }

                  // Actualizar la posición de la bola en movimiento
                  movingBalls[line.id].setAttribute('cx', point.x);
                  movingBalls[line.id].setAttribute('cy', point.y);

                  // Mantener la posición del jugador estática
                  updatePlayerPosition(playerId, playerCurrent.x, playerCurrent.y);

                } else {
                  // Mover el jugador a lo largo de la ruta
                  const totalLength = pathElement.getTotalLength();
                  const point = pathElement.getPointAtLength(t * totalLength);
                  updatePlayerPosition(playerId, point.x, point.y);
                }
              }
            } else {
              // Interpolar posición
              const x = playerCurrent.x + (playerNext.x - playerCurrent.x) * t;
              const y = playerCurrent.y + (playerNext.y - playerCurrent.y) * t;
              updatePlayerPosition(playerId, x, y);
            }

            // Manejar estado del balón
            if (t >= 1) {
              updatePlayerHasBall(playerId, playerNext.hasBall);
            } else {
              // Si el jugador está pasando el balón, no debe tener el balón durante el pase
              if (line && line.type === 'dashed' && playerCurrent.hasBall) {
                updatePlayerHasBall(playerId, false);
              } else {
                const hasBall = playerCurrent.hasBall;
                updatePlayerHasBall(playerId, hasBall);
              }
            }
          }
        });
      }

      function updatePlayerPosition(playerId, x, y) {
        const playerElement = players[playerId];
        if (playerElement) {
          const textElement = playerElement.querySelector('text');
          textElement.setAttribute('x', x);
          textElement.setAttribute('y', y);

          const circleElement = playerElement.querySelector('circle');
          if (circleElement) {
            circleElement.setAttribute('cx', x);
            circleElement.setAttribute('cy', y);
          }
        }
      }

      function updatePlayerHasBall(playerId, hasBall) {
        const playerElement = players[playerId];
        if (playerElement) {
          const circleElement = playerElement.querySelector('circle');
          if (hasBall && !circleElement) {
            // Añadir círculo
            const newCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
            newCircle.setAttribute('cx', playerElement.querySelector('text').getAttribute('x'));
            newCircle.setAttribute('cy', playerElement.querySelector('text').getAttribute('y'));
            newCircle.setAttribute('r', '12');
            newCircle.setAttribute('stroke', 'black');
            newCircle.setAttribute('stroke-width', '2');
            newCircle.setAttribute('fill', 'none');
            playerElement.insertBefore(newCircle, playerElement.firstChild);
          } else if (!hasBall && circleElement) {
            // Eliminar círculo
            playerElement.removeChild(circleElement);
          }
        }
      }

      // Iniciar la animación
      requestAnimationFrame(animate);    `;
  };

  const generatePathD = (
    line: Line,
    adjustedFrom: { x: number; y: number },
    adjustedTo: { x: number; y: number }
  ) => {
    if (line.type === 'zigzag') {
      return createZigzagPath(
        adjustedFrom,
        { x: line.controlX, y: line.controlY },
        adjustedTo
      );
    } else {
      return `M ${adjustedFrom.x} ${adjustedFrom.y} Q ${line.controlX} ${line.controlY} ${adjustedTo.x} ${adjustedTo.y}`;
    }
  };

  const currentFrame = frames[currentFrameIndex];

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.onClose(null)}
        fullScreen
        PaperProps={{
          style: {
            width: '98%',
            height: '98%',
            margin: 'auto',
            borderRadius: '8px'
          },
        }}
      >
        <div>

          < Box sx={{ p: 1, bgcolor: theme.palette.primary.main, textAlign: 'center' }}>
            <Grid container alignItems="center">

              {/* Left aligned Play button */}
              <Grid item xs={state.portrait ? 6 : 4} sx={{ textAlign: 'left' }}>
                <Button
                  variant="outlined"
                  onClick={() => setIsModalOpen(true)}
                  size={state.portrait ? "small" : "medium"}
                  style={{ color: "white", textTransform: 'none', borderColor: 'white', borderRadius: '6px', fontWeight: 'bold' }}
                >
                  <PlayArrowRoundedIcon />
                  Animation
                </Button>

              </Grid>

              {/* Centered title */}
              {!state.portrait &&
                <Grid item xs={state.portrait ? 0 : 4}>
                  <Typography sx={{ fontSize: { xs: '16px', md: '20px' }, fontWeight: 'bold', color: 'white' }} align="center">
                    {t('PlayBuilder06')}
                  </Typography>
                </Grid>
              }

              {/* Right aligned Save Play button */}
              <Grid item xs={state.portrait ? 6 : 4} sx={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  size={state.portrait ? "small" : "medium"}
                  style={{ color: theme.palette.primary.main, textTransform: 'none', backgroundColor: 'white', borderRadius: '6px', fontWeight: 'bold' }}
                  onClick={() => {
                    const playDetails: PlayDetails = {
                      frames: frames,
                      currentFrameIndex: currentFrameIndex,
                      dragging: dragging,
                      selectedPlayerId: selectedPlayerId,
                      courtType: courtType,
                    }
                    props.onClose(playDetails)

                  }}
                >
                  <SaveRoundedIcon />
                  {t('CreatePlay08')}
                </Button>
                <IconButton sx={{ color: 'white', marginLeft: '10px' }} onClick={() => props.onClose(null)}>
                  <CloseRoundedIcon />
                </IconButton>
              </Grid>

            </Grid>
          </Box >

          <Grid container style={{ height: state.portrait ? 'auto' : '100vh' }}>
            {/* Left Sidebar */}
            <Grid item xs={state.portrait ? 12 : 3} md={state.portrait ? 12 : 3} style={{ padding: state.portrait ? '0px' : '10px', borderRight: state.portrait ? 'none' : '1px solid #ccc', backgroundColor: state.portrait ? 'white' : '#f1f1f1', overflowY: 'auto', paddingBottom: state.portrait ? '0px' : '300px' }}>
              <PhasesSectionPlayBuilder
                frames={frames}
                currentFrameIndex={currentFrameIndex}
                handleSelectFrame={handleSelectFrame}
                handleDeleteFrame={handleDeleteFrame}
                handleCreateNewFrame={handleCreateNewFrame}
              />
            </Grid>

            {/* SVG */}
            <Grid item xs={12} md={6} style={{ position: 'relative', maxWidth: `${MAX_SVG_WIDTH}px`, margin: '0 auto' }}>

              {/* SHOW TITLE IN LANDSCAPE. WE SHOW IT IN PHASES SECTION FOR BETTER UI WHEN ON PORTRAIT */}
              {!state.portrait &&
                <Typography style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: state.portrait ? '0px' : '10px', paddingBottom: state.portrait ? '5px' : '10px' }}>
                  {location.state.attributes.title || ''}
                </Typography>
              }
              <svg
                ref={svgRef}
                viewBox={`0 0 ${courtWidth} ${courtHeight}`}
                style={{
                  width: '100%',
                  height: 'auto',
                  touchAction: 'none' //it prevents default behavior of touch events. That's needed for preventing error on the console cause it conflics with scroll / zoom in mobile devices
                }}
                preserveAspectRatio="xMidYMid meet"
                onMouseDown={() => {
                  setSelectedPlayerId(null);
                  setDragging({ isDragging: false, type: null, id: null });
                }}
              >
                {courtType === 'halfcourt' ? <BasketballHalfCourt /> : <BasketballFullCourt />}
                <SvgDefs />

                {currentFrame.lines.map((line) => {
                  const fromPlayer = currentFrame.players.find(
                    (p) => p.id === line.fromId
                  );
                  if (!fromPlayer) return null;

                  const isLineConnectedToSelected =
                    selectedPlayerId === line.fromId;

                  const adjustedFrom = getOffsetPoint(
                    fromPlayer.x,
                    fromPlayer.y,
                    line.toX,
                    line.toY,
                    15
                  );

                  const adjustedTo = getOffsetPoint(
                    line.toX,
                    line.toY,
                    fromPlayer.x,
                    fromPlayer.y,
                    15
                  );

                  const pathD = generatePathD(line, adjustedFrom, adjustedTo);

                  return (
                    <g key={line.id}>
                      <path
                        d={pathD}
                        stroke="black"
                        strokeWidth={2}
                        fill="none"
                        strokeDasharray={line.type === 'dashed' ? '5,5' : 'none'}
                        markerEnd={
                          line.type === 'bar' ? 'url(#barEnd)' : 'url(#arrowhead)'
                        }
                      />
                      {isLineConnectedToSelected && (
                        <>
                          <circle
                            cx={line.controlX}
                            cy={line.controlY}
                            r={6}
                            fill="purple"
                            stroke="black"
                            strokeWidth={1}
                            style={{ cursor: 'pointer' }}
                            onMouseDown={(e) => handleStart(e, 'control', line.id)}
                            onTouchStart={(e) => handleStart(e, 'control', line.id)}
                          >
                            <title>{t('PlayBuilder07')}</title>
                          </circle>
                          {/* Círculo transparente para ampliar el área de selección del Punto de Control */}
                          <circle
                            cx={line.controlX}
                            cy={line.controlY}
                            r={12} // Radio aumentado para mayor área de selección
                            fill="transparent"
                            onMouseDown={(e) => handleStart(e, 'control', line.id)}
                            onTouchStart={(e) => handleStart(e, 'control', line.id)}
                            style={{ cursor: 'pointer' }}
                          />

                          <circle
                            cx={line.toX}
                            cy={line.toY}
                            r={8}
                            fill="orange"
                            stroke="black"
                            strokeWidth={1}
                            style={{ cursor: 'pointer' }}
                            onMouseDown={(e) => handleStart(e, 'end', line.id)}
                            onTouchStart={(e) => handleStart(e, 'end', line.id)}
                          >
                            <title>Punto Final</title>
                          </circle>
                          {/* Círculo transparente para ampliar el área de selección del Punto Final */}
                          <circle
                            cx={line.toX}
                            cy={line.toY}
                            r={12} // Radio aumentado para mayor área de selección
                            fill="transparent"
                            onMouseDown={(e) => handleStart(e, 'end', line.id)}
                            onTouchStart={(e) => handleStart(e, 'end', line.id)}
                            style={{ cursor: 'pointer' }}
                          />
                        </>
                      )}
                    </g>
                  );
                })}

                {currentFrame.players.map((player) => (
                  <g
                    key={player.id}
                    onMouseDown={(e) => handleStart(e, 'player', player.id)}
                    onTouchStart={(e) => handleStart(e, 'player', player.id)}
                    onClick={() => setSelectedPlayerId(player.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {/* this transparent circle increases the range of selection */}
                    <circle
                      cx={player.x}
                      cy={player.y}
                      r={15}
                      fill="transparent"
                    />
                    {selectedPlayerId === player.id && (
                      <rect
                        x={player.x - 14}
                        y={player.y - 14}
                        width={28}
                        height={28}
                        rx={2}
                        ry={2}
                        fill="#f1f1f1"
                        stroke="black"
                        strokeWidth={1}
                        strokeDasharray="4,2"
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          from="0"
                          to="6"
                          dur="2s"
                          repeatCount="indefinite"
                        />
                      </rect>
                    )}
                    {player.hasBall && (
                      <circle
                        cx={player.x}
                        cy={player.y}
                        r={12}
                        stroke="black"
                        strokeWidth={2}
                        fill="none"
                      />
                    )}
                    <text
                      x={player.x}
                      y={player.y}
                      textAnchor="middle"
                      alignmentBaseline="central"
                      fontSize="18"
                      fontWeight="bold"
                      fill="black"
                      style={{ userSelect: 'none', pointerEvents: 'none' }}
                    >
                      {player.number}
                    </text>
                  </g>
                ))}
              </svg>

              <Grid item xs={4} sx={{ textAlign: 'right' }}>
                <ToggleButtonGroup
                  value={courtType}
                  exclusive
                  onChange={handleCourtChange}
                  aria-label="Tipo de cancha"
                  size="small"
                  sx={{ backgroundColor: 'white', borderRadius: '6px' }}
                >
                  <ToggleButton value="halfcourt" aria-label="Halfcourt">
                    {t('PlayBuilder09')}
                  </ToggleButton>
                  <ToggleButton value="fullcourt" aria-label="Fullcourt">
                    {t('PlayBuilder10')}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>

            </Grid>

            {/* Right Sidebar */}
            <Grid item xs={state.portrait ? 12 : 8} md={state.portrait ? 12 : 3}
              style={{
                padding: '10px',
                borderLeft: state.portrait ? 'none' : '1px solid #ccc',
                backgroundColor: state.portrait ? 'white' : '#f1f1f1'
              }}>
              <ActionButtons
                selectedPlayerId={selectedPlayerId}
                createLine={createLine}
                handleCreateNewFrame={handleCreateNewFrame}
                setIsModalOpen={setIsModalOpen}
                handleDeleteLine={handleDeleteLine}
                handleToggleBall={handleToggleBall}
                handleSavePlay={handleSavePlay}
              />
            </Grid>
          </Grid>
        </div>
      </Dialog>

      {/* Animation Modal */}
      {
        isModalOpen && (
          <DialogPlayAnimation
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            frames={frames}
            generateSVGContent={generateSVGContent}
            generateAnimationScript={generateAnimationScript}
          />
        )
      }
    </>
  );
};