import { useState, useEffect, useContext } from 'react';
import { Box, Typography, Modal, Card, CardContent, Button, Checkbox, IconButton, FormControlLabel, FormGroup } from '@mui/material';
import log from "../misc/Logger";
import { Context } from '../App';
import { saveUser, } from '../misc/Functions';
import { ActionShowWelcomePresentation, TypeContext } from '../misc/Types';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
// import LanguageICB from './LanguageICB';

export function WelcomePresentation() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const theme = useTheme();
    log.debug('WelcomePresentation');

    // const [open, setOpen] = useState(false);
    // const [showWelcomeCard, setShowWelcomeCard] = useState(true);
    const showWelcomeCard = true
    const [disabled, setDisabled] = useState(false);
    const [animationStarted, setAnimationStarted] = useState(false);

    useEffect(() => {
        if (showWelcomeCard) {
            setTimeout(() => {
                setAnimationStarted(true);
            }, 100); // This delay ensures that the card has been rendered before starting the animation.
        }
    }, [showWelcomeCard]);

    // When user presses checkbox to not show intro again then user profile is udpated.
    // Do not allow reclick of check box or close of dialog before update of user profile has been completed.
    // Otherwise, Playwright tests with subsequent change of subscription overrides the profile update.
    useEffect(() => {
        setDisabled(false)
        log.debug('WelcomePresentation(), enable controls'); // Needed to debug Playwright test with fast creation of multiple users
    }, [state.user.data])

    async function handleClickCheckbox() {
        setDisabled(true);
        await saveUser(state, dispatch, {
            attributes: {
                field_ui_settings: JSON.stringify({ ...state.uiSettings, showGuidedTour: false }) // showDescriptionInPortrait: showDescriptionInPortrait })
            }
        })
    }

    return (
        <Modal
            // open={open}
            open={state.showWelcomePresentation}
            onClose={() => {
                let action: ActionShowWelcomePresentation = { type: 'showWellcomePresentation', show: false };
                dispatch(action);
            }}
            aria-labelledby="presentation-modal"
            aria-describedby="modal-with-video-presentation"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: showWelcomeCard ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.5)',
                // zIndex: 5000 // Setting z-index to 5000 for the modal
            }}
        // BackdropProps={{ onClick: (event) => event.stopPropagation() }}
        >
            {showWelcomeCard ? (
                <Card sx={{
                    // backgroundImage: 'linear-gradient(to bottom, #15468F, #D47029)',
                    // backgroundColor: 'white',
                    width: '85%',  // Make the card wider
                    maxWidth: '500px',
                    borderRadius: '15px',
                    boxShadow: '5px 5px 25px rgba(0,0,0,0.2)',
                    zIndex: 5001,  // Ensuring the card is above the modal
                    fontFamily: 'PTSans, sans-serif',  // Using PTSans font
                    textAlign: 'center',  // Left-align content

                    // Animación
                    opacity: animationStarted ? 1 : 0,
                    transform: animationStarted ? 'translateX(0)' : 'translateX(-100%)',
                    transition: 'opacity 0.5s, transform 2s',
                }}>
                    <CardContent>
                        <IconButton
                            aria-label="welcomepresentation_close"
                            disabled={disabled}
                            onClick={() => {
                                let action: ActionShowWelcomePresentation = { type: 'showWellcomePresentation', show: false };
                                dispatch(action);
                            }}
                            sx={{
                                position: 'absolute',  // Position the button absolutely
                                top: '10px',  // Adjust these values as needed
                                right: '10px',
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography gutterBottom variant="h4" component="div" style={{
                            color: theme.palette.primary.main, fontWeight: 'bold', fontSize: '1.5rem', fontFamily: 'PT Sans, sans-serif', marginTop: 25
                        }}>
                            {t("WelcomePresentation05")}
                        </Typography>

                        {
                            state.portrait &&
                            <Typography gutterBottom variant="subtitle1" component="div" style={{ color: theme.palette.primary.main, fontFamily: 'PT Sans, sans-serif' }}>
                                {t("WelcomePresentation06")}
                            </Typography>
                        }

                        {
                            !state.portrait &&
                            <Typography gutterBottom variant="subtitle1" component="div" style={{ color: theme.palette.primary.main, fontFamily: 'PT Sans, sans-serif' }}>
                                {t("WelcomePresentation07")}
                            </Typography>
                        }

                        <br />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '5px' }}>
                            <Button
                                aria-label='welcomepresentation_starttour'
                                variant="contained"
                                color="primary"
                                sx={{
                                    backgroundColor: '#1c54e4',  // Set button color to D47029
                                    borderRadius: '6px',  // Make button edges round
                                    fontWeight: 'bold',  // Make button text bold
                                    width: '45%',  // Decrease button width
                                }}
                                onClick={() => {
                                    let action: ActionShowWelcomePresentation = { type: 'showWellcomePresentation', show: false };
                                    dispatch(action);
                                }}
                            >
                                {t("WelcomePresentation08")}

                            </Button>
                        </Box>

                        {/* Divider */}
                        <div style={{ height: '1px', backgroundColor: theme.palette.divider, margin: '35px auto 15px auto', width: '80%', maxWidth: '325px' }}> </div>

                        {/* Don't show checkbox for anonymous user */}
                        {
                            state.user.data.id === "" ? '' :
                                < Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',  // <-- This centers the contents horizontally
                                        color: 'darkgrey'
                                    }}>

                                    <FormGroup
                                        aria-label='welcomepresentation_donotshowagain'
                                    >
                                        <FormControlLabel control={
                                            <Checkbox
                                                color="primary"
                                                size="small"
                                                sx={{ color: 'lightgrey', '&.Mui-checked': { color: theme.palette.primary.main } }}
                                                disabled={disabled}
                                                onChange={() => handleClickCheckbox()}
                                            />
                                        } label={
                                            <Typography variant="body2">
                                                {t("WelcomePresentation09")}
                                            </Typography>
                                        } />
                                    </FormGroup>
                                </Box>
                        }
                    </CardContent>
                </Card>
            ) : (
                <Box></Box>
            )
            }
        </Modal >
    );
}