import { Fragment, useContext, useEffect, useState } from "react";
import log from "../misc/Logger";
import { getDD, mapPractice, mondaySunday } from "../misc/Functions";
import DateFromDateTo from "./DateFromDateTo";
import TeamDropdown from "./TeamDropdown";
import { Context } from "../App";
import { ActionSetUISettings, TypeContext } from "../misc/Types";
import { useTranslation } from "react-i18next";
import { BarChart } from "@mui/x-charts";
import { Box, Button, Card, Divider, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DialogHowToCheckAttendance from "./DialogHowToCheckAttendance";
import { BACKEND } from "../misc/Constants";

// Form data
interface TypeAttendanceReport {
    date_from: Date,
    date_to: Date,
    team: string,
}

// Attendance for a single player for at single practice
interface AttendancePlayer {
    date: Date,
    playerID: string,
    present: boolean,
    playerName: string,
}

export default function AttendanceReport() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const theme = useTheme();
    log.debug('AttendanceReport');

    const [disabled, setDisabled] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const [selectedPlayers, setSelectedPlayers] = useState<{ [key: string]: { playerName: string, presentCount: number, totalCount: number } }>({});

    const expandPlayerReport = (playerID: string) => {
        setSelectedPlayers(prevSelected => {
            const newSelected = { ...prevSelected };
            if (newSelected[playerID]) {
                delete newSelected[playerID]; // Deselect if already selected
            } else {
                const player = playersAttendancePercentage[playerID];
                if (player) {
                    newSelected[playerID] = player; // Select player
                }
            }
            return newSelected;
        });
    };

    // Handle refresh/F5 and change of toggle show attendance
    useEffect(() => {
        setShowContent(state.uiSettings.showAttendance)
        setDisabled(false)
    }, [state.uiSettings.showAttendance])

    // // User has flopped switch to show or not show exercise description
    // async function handleChange_field_ui_settings() {
    //     setDisabled(true)
    //     setShowContent(!showContent)
    //     await saveUser(state, dispatch, {
    //         attributes: {
    //             field_ui_settings: JSON.stringify({ ...state.uiSettings, showAttendance: !showContent })
    //         }
    //     })
    // }

    const [monday, sunday] = mondaySunday()

    // Default form data
    const formDataObj: TypeAttendanceReport = {
        date_from: monday,
        date_to: sunday,
        team: '',
    }

    const [formData, setFormData] = useState<TypeAttendanceReport>(formDataObj)

    // Practice dates and attendance report rows
    const [dates, setDates] = useState<Array<Date>>([])
    const [rows, setRows] = useState<Array<AttendancePlayer>>([])

    // Get teams for dropdown and default selection
    useEffect(() => {
        const formDataLocal = {
            ...formData,
            // Team is current practice team, users first team or ''
            team: state.curPractice.team?.attributes.title
                || state.allTeams.find(x => x.relationships.uid.data.id === state.user.data.id)?.attributes.title
                || ''
        }
        setFormData(formDataLocal)
    }, [state.allTeams])

    // Find attendance at startup and each time form data changes
    useEffect(() => {
        const practices = state.allPractices.map(x => mapPractice(state, x)).filter(y => y.team.attributes.title === formData.team
            && y.date >= new Date(formData.date_from)
            && y.date <= new Date(formData.date_to)
        )
        // Array of all player show up, yes/no, for all practices
        const playerDates = practices.map((item) =>
            item.attendance.map((player) => {
                const playerLocal = state.allPlayers.find(x => x.id === player.playerID)
                return {
                    date: item.date,
                    playerID: player.playerID,
                    present: player.present,
                    playerName: playerLocal ? playerLocal.attributes.field_player_first_name + ' ' + playerLocal.attributes.field_player_last_name : t('AttendanceReport00')
                }
            })).flat()
        // Get practice dates
        setDates([...new Set(playerDates.map((row) => row.date))])
        // Make sure players that are deleted and show as 'No Player Info' have an index in the case more that one player has been deleted
        playerDates.filter(x => x.playerName === t('AttendanceReport00')).forEach((y, index) => y.playerName = `${y.playerName} ${index + 1}`)
        // Sort rows 
        setRows(playerDates.sort(compareFn))
    }, [formData, state.allPractices])

    // Update form data when value in form changes
    const handleChange = (name: string, value: any) => {
        setFormData({ ...formData, [name]: value })
    };

    // Sort array of AttendancePlayer by playerID, date
    function compareFn(a: AttendancePlayer, b: AttendancePlayer) {
        if (a.playerName < b.playerName) {
            return -1;
        } else if (a.playerName > b.playerName) {
            return 1;
        } else if (a.date < b.date) {
            return -1
        } else if (a.date > b.date) {
            return 1
        }
        return 0;
    }

    //WE CALCULATE THIS VALUES TO DISPLAY IN THE CHART
    const chartSetting = {
        yAxis: [
            {
                label: t('AttendanceReport05'),
            },
        ],
        height: 350,
    };

    const playerAttendance = rows.reduce((acc, row) => {
        if (!acc[row.playerID]) {
            acc[row.playerID] = { playerName: row.playerName, presentCount: 0 };
        }
        if (row.present) {
            acc[row.playerID].presentCount += 1;
        }
        return acc;
    }, {} as { [key: string]: { playerName: string, presentCount: number } });

    const chartData = Object.values(playerAttendance);

    const valueFormatter = (value: number | null) => `${value}`;

    //WE CALCULATE THIS VALUES TO DISPLAY IN SUMMARY
    const playersAttendancePercentage = rows.reduce((acc, row) => {
        if (!acc[row.playerID]) {
            acc[row.playerID] = { playerName: row.playerName, presentCount: 0, totalCount: 0 };
        }
        // Solo contar si hay datos (0 o 1)
        if (row.present !== null) {
            acc[row.playerID].totalCount += 1;
            if (row.present) {
                acc[row.playerID].presentCount += 1;
            }
        }
        return acc;
    }, {} as { [key: string]: { playerName: string, presentCount: number, totalCount: number } });

    const players100 = [];
    const players70to100 = [];
    const playersLess70 = [];

    for (const playerID in playersAttendancePercentage) {
        const player = playersAttendancePercentage[playerID];
        // Calcular el porcentaje solo si totalCount es mayor que 0
        const attendancePercentage = player.totalCount > 0 ? (player.presentCount / player.totalCount) * 100 : 0;

        if (attendancePercentage === 100) {
            players100.push(player.playerName);
        } else if (attendancePercentage > 70) {
            players70to100.push(player.playerName);
        } else {
            playersLess70.push(player.playerName);
        }
    }

    //THIS ALLOWS US TO DISPLAY DIFFERENT COLORS FOR EACH BAR OF THE CHART DEPENDING ON THE ATTENDANCE PERCENTAGE
    const namesArray = chartData.map(data => data.playerName);
    const barColors = chartData.map((data) => {
        const player = Object.values(playersAttendancePercentage).find(p => p.playerName === data.playerName);
        const attendancePercentage = player && player.totalCount > 0 ? (player.presentCount / player.totalCount) * 100 : 0;
        if (attendancePercentage === 100) {
            return "#74cc14"; // green
        } else if (attendancePercentage >= 70) {
            return "lightgray"; // gray
        } else {
            return "red"; // red
        }
    });

    //THIS ALLOWS US TO DISPLAY DIFFERENT COLORS FOR EACH INDIVIDUAL REPORT OF THE CHART DEPENDING ON THE ATTENDANCE PERCENTAGE
    const playerColors = chartData.map((data) => {
        const player = Object.values(playersAttendancePercentage).find(p => p.playerName === data.playerName);
        const attendancePercentage = player && player.totalCount > 0 ? (player.presentCount / player.totalCount) * 100 : 0;
        if (attendancePercentage === 100) {
            return "#e6ee9c"; // light green
        } else if (attendancePercentage >= 70) {
            return "#f4f4f4"; // light gray
        } else {
            return "#ffab91"; // light red
        }
    });

    //CALCULATING SUMMARY
    const totalPracticesWithData = dates.filter(date => rows.some(row => row.date === date && row.present !== null)).length;
    const totalPossibleAttendances = rows.filter(row => row.present !== null).length;
    const totalAttendanceWithData = rows.filter(row => row.present).length;
    const averageAttendanceWithData = totalPracticesWithData ? (totalAttendanceWithData / totalPracticesWithData) : 0;
    const attendancePercentage = totalPossibleAttendances ? (totalAttendanceWithData / totalPossibleAttendances) * 100 : 0;

    const summaryCards = [
        { title: t('AttendanceReport06'), value: totalPracticesWithData },
        { title: t('AttendanceReport07'), value: averageAttendanceWithData.toFixed(2) },
        { title: t('AttendanceReport08'), value: `${attendancePercentage.toFixed(2)}%` }
    ].map((item, index) => (
        <Grid2 key={index} xs={4} sx={{ padding: 1 }}>
            <Box sx={{ padding: 1, textAlign: 'center', borderRadius: '10px' }}>
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', }}>
                    {item.value}
                </Typography>
                <Typography sx={{ fontSize: '11px' }}>
                    {item.title}
                </Typography>
            </Box>
        </Grid2>
    ))

    return (
        <Fragment>
            <Card elevation={0} style={{ marginTop: 20, border: `1px solid ${theme.palette.divider}` }}>
                <Grid2 container xs={12} sx={{ backgroundColor: '#FFB47E', width: '100%', height: '50px', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                    <Typography style={{ color: 'white', fontSize: '18px', fontWeight: 'bold', textAlign: 'center', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                        {t('AttendanceReport01')}
                    </Typography>
                    <IconButton
                        // onClick={handleChange_field_ui_settings}
                        onClick={() => {
                            setDisabled(true)
                            const ui_settings = {
                                ...state.uiSettings,
                                showAttendance: !state.uiSettings.showAttendance,
                            }
                            getDD(state, dispatch, `${BACKEND}/icb-user/set_first_person_user_field_value/field_ui_settings/${JSON.stringify(ui_settings)}`)
                                .then(() => {
                                    const action: ActionSetUISettings = { type: 'setUISettings', uiSettings: ui_settings }
                                    dispatch(action)
                                })
                        }}
                        sx={{ position: 'absolute', right: 0, color: 'white' }}
                        disabled={disabled}
                    >
                        {showContent ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </Grid2>

                {showContent && (
                    <Fragment>
                        <Divider />
                        <Box sx={{ padding: 1, paddingTop: 2 }}>
                            <TeamDropdown
                                handleChange={(value) => handleChange('team', value)}
                                selected={formData.team}
                            />

                            <DateFromDateTo
                                handleChange={(name, value) => handleChange(name, value)}
                                date_from={formData.date_from}
                                date_to={formData.date_to}
                            />
                        </Box>

                        {chartData.length > 0 ? (
                            <Fragment>
                                <Grid2 container xs={12} mt={1} sx={{ justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                    {[
                                        { color: '#74cc14', label: '100%' },
                                        { color: '#f4f4f4', label: '70-100%' },
                                        { color: 'red', label: t('AttendanceReport04') }
                                    ].map((item, index) => (
                                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                                            <Box sx={{ width: 20, height: 20, backgroundColor: item.color, mr: 1, borderRadius: '4px' }} />
                                            <Typography sx={{ color: theme.palette.text.secondary, fontSize: '12px' }}>{t(item.label)}</Typography>
                                        </Box>
                                    ))}

                                    <Tooltip
                                        title={t('AttendanceReport09')}
                                        enterTouchDelay={0}
                                        leaveTouchDelay={60000}
                                    >
                                        <IconButton edge="end" aria-label="close" sx={{ position: 'absolute', right: 20 }}>
                                            <InfoOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid2>

                                {/* POINTER EVENTS "NONE" IS NECESSARY TO ALLOW SCROLL WHEN ONTOUCH DEVICE */}
                                <Box sx={{ pointerEvents: state.portrait ? 'none' : 'auto' }}>
                                    <BarChart
                                        dataset={chartData}
                                        margin={{ bottom: 100 }}
                                        borderRadius={10}
                                        colors={['white']}
                                        xAxis={[{
                                            data: namesArray,
                                            scaleType: "band",
                                            colorMap: {
                                                type: "ordinal",
                                                values: namesArray,
                                                colors: barColors,
                                            },
                                            tickLabelStyle: { angle: -45, fontSize: '10px', textAnchor: 'end' }
                                        }]}
                                        series={[{ dataKey: 'presentCount', label: t('AttendanceReport05'), valueFormatter }]}
                                        layout="vertical"
                                        grid={{ vertical: true }}
                                        {...chartSetting}
                                    />
                                </Box>

                                <Box sx={{ margin: 'auto', width: '95%', marginTop: 1 }}>
                                    <Divider />
                                </Box>

                                <Grid2 container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {summaryCards}
                                </Grid2>

                                {/* INDIVIDUAL PLAYER REPORT */}
                                <Box sx={{ padding: 1 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 0 }}>
                                        {Object.keys(playerAttendance).map((playerID) => {
                                            const player = playerAttendance[playerID];
                                            return (
                                                <Fragment key={playerID} >
                                                    <Button
                                                        onClick={() => expandPlayerReport(playerID)}
                                                        variant={selectedPlayers[playerID] ? 'outlined' : 'text'}
                                                        sx={{
                                                            //transition: 'all 0.2s ease',
                                                            textTransform: 'none',
                                                            color: 'black',
                                                            flexDirection: 'column',
                                                            alignItems: 'flex-start',
                                                            background: playerColors[namesArray.indexOf(player.playerName)],
                                                            border: '1px solid transparent',
                                                            '&:hover': {
                                                                background: playerColors[namesArray.indexOf(player.playerName)],
                                                                border: `1px solid ${theme.palette.divider}`,
                                                            },
                                                        }}
                                                    >
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                {player.playerName}
                                                            </Typography>
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                {((playersAttendancePercentage[playerID].presentCount / playersAttendancePercentage[playerID].totalCount) * 100).toFixed(2)}%
                                                            </Typography>
                                                        </Box>

                                                        {selectedPlayers[playerID] && (
                                                            <Fragment>

                                                                <Typography sx={{ fontSize: '12px', mt: 1, ml: 1 }}>
                                                                    {`${t('AttendanceReport12')}: ${totalPracticesWithData}`}
                                                                </Typography>
                                                                <Typography sx={{ fontSize: '12px', ml: 1 }}>
                                                                    {`${t('AttendanceReport13')}: ${playersAttendancePercentage[playerID].presentCount} / ${playersAttendancePercentage[playerID].totalCount}`}
                                                                </Typography>
                                                                <Typography sx={{ fontSize: '12px', mt: 1, ml: 1, fontWeight: 'bold' }}>
                                                                    {`${t('AttendanceReport14')}: ${((playersAttendancePercentage[playerID].presentCount / playersAttendancePercentage[playerID].totalCount) * 100).toFixed(2)}%`}
                                                                </Typography>

                                                                {/* CASE WHERE NUMBER OF PRACTICES OF THE TEAM AND MAX. POSSIBLE ATTENDANCE FOR PLAYER IS DIFFERENT */}
                                                                {/* THIS WILL HAPPEN IF A PLAYER WAS NOT PART OF THE TEAM IN THE MOMENT THEY CHECKED*/}
                                                                {/* ATTENDANCE FOR A SPECIFIC PRACTICE */}

                                                                {totalPracticesWithData !== playersAttendancePercentage[playerID].totalCount && (
                                                                    <Typography sx={{ fontSize: '12px', mt: 1, ml: 1, color: 'red', textAlign: 'left' }}>
                                                                        {t('* El número de entrenamientos del equipo y las asistencias máximas posibles de este jugador/a no coinciden. Esto es debido a que en algunos entrenamientos del periodo seleccionado, este jugador/a no formaba parte del equipo.')}
                                                                    </Typography>
                                                                )}

                                                            </Fragment>
                                                        )}
                                                    </Button>
                                                </Fragment>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            </Fragment>
                        ) : (
                            <Typography textAlign={'center'} sx={{ fontSize: { xs: 14, sm: 17 }, fontFamily: 'PT Sans, sans-serif', color: "grey" }} marginTop={1} marginBottom={1}>
                                {t('AttendanceReport10')}
                            </Typography>
                        )}
                    </Fragment>
                )}

                {/* Show howto for attendance lists if user has no attendance information at all in all his practices */}
                {
                    showContent &&
                    <DialogHowToCheckAttendance
                        show={state.practicesRetrieved && state.allPractices.map(x => mapPractice(state, x)).filter(y => y.attendance.length > 0).length === 0}
                        locale={state.user.locale.substring(0, 2)}
                    />
                }

            </Card >
        </Fragment >
    )
}