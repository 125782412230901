import { Box, Button, Divider, MenuItem, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import log from "../misc/Logger";
import { Context } from '../App';
import { ActionSetSelectedExercisePool, ActionSetSelectedGroup, CRUD, DrupalEntity, OperationMode, TypeContext, UserType } from '../misc/Types';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { getActionSetConfirm, getAllContentEntities, icbControllerGenerel02, nodeCRUD } from '../misc/Functions';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { useTheme } from '@mui/material/styles';
// import SnackbarMessages from './SnackbarMessages';
// import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
// import { DRUPALENTITYINIT } from '../misc/Constants';
import { useNavigate } from 'react-router-dom';
import DialogPlayBuilder, { PlayDetails } from './playbook/DialogPlayBuilder';
import { PLAYDETAILS } from '../misc/Constants';

interface PropsCreatePlay {
    play: DrupalEntity
}

export default function CreatePlay(props: PropsCreatePlay) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    log.debug('CreatePlay');

    const [openDialogPlayBuilder, setOpenDialogPlayBuilder] = useState(false)
    const [playDetails, setPlayDetails] = useState<PlayDetails | null>(
        // if we have a play the use play details from that play. Otherwise, use initials play details
        props.play.id
            ? JSON.parse(props.play.attributes.field_play_details)
            : PLAYDETAILS
    )

    const validationSchema = yup.object({
        title: yup
            .string()
            .required(t('CreatePlay00'))
            // allow save if no other play with same name or play with same name has same id as the one we try to save
            .test('new-play', t("PLAY - ${path} name exists already"), (value) => {
                const rc = state.allPlays.find(x => x.attributes.title === value) === undefined
                    || state.allPlays.find(x => x.attributes.title === value)?.id === props.play.id
                return rc
            }),
        field_play_description: yup
            .string(),
        field_play_group: yup
            .string()
            .required(t('CreatePlay01')),
    });

    const formik = useFormik({
        initialValues: {
            title: (props.play.attributes.title || '') as string,
            field_play_description: (props.play.attributes.field_play_description || '') as string,
            field_play_group: (props.play.relationships.field_play_group?.data.id || '') as string,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            let node: DrupalEntity = {
                type: 'node--play',
                attributes: {
                    title: values.title,
                    field_play_description: values.field_play_description,
                    field_play_details: JSON.stringify(playDetails),//props.getPlayDetails(),
                },
                relationships: {
                    field_play_group: {
                        data: {
                            type: 'node--play',
                            id: values.field_play_group
                        }
                    }
                }
            }

            let CRUDOperation: CRUD = CRUD.Create // assume new play
            if (props.play.id) {
                CRUDOperation = CRUD.Update // update play
                node['id'] = props.play.id
            }

            nodeCRUD(state, dispatch, CRUDOperation, node)
                .then((resp) => {
                    if (resp.data) {
                        // setPlay(resp.data)

                        // Try to go where new play is and show play to user!
                        let actionSetSelectedExercisePool: ActionSetSelectedExercisePool = {
                            type: 'setSelectedExercisePool',
                            pool: state.user.data.attributes.field_user_type === UserType.clubadmin ? 'club' : 'mine'
                        }
                        dispatch(actionSetSelectedExercisePool);
                        let actionSetSelectedGroup: ActionSetSelectedGroup = { type: 'setSelectedGroup', group: state.allGroups.find(x => x.id === values.field_play_group)! };
                        dispatch(actionSetSelectedGroup);
                        window.scrollTo(0, 0);
                        navigate('/icbplays');
                        // Make sure all display is available to show plays
                        if (state.showPracticeProgram && state.portrait) {
                            let action = { type: 'showPracticeProgram', show: false };
                            dispatch(action);
                        }
                    } else {
                        dispatch(getActionSetConfirm(resp));
                    }
                })
        }
    });

    function handleDelete() {
        // nodeCRUD(state, dispatch, CRUD.Delete, {
        //     id: play.id,
        //     type: 'node--play',
        // })
        //     .then((resp) => {
        //         if (resp) {
        //             dispatch(getActionSetConfirm(resp));
        //         } else {
        //             navigate(-1)
        //         }
        //     })

        // delete play and remove play from playbooks
        icbControllerGenerel02(state, {
            opr: "delete_play",
            playID: props.play.id
        })
            .then((resp) => {
                if (resp.ok) {
                    getAllContentEntities(state, dispatch, 'node--play')
                    getAllContentEntities(state, dispatch, 'node--playbook')
                    navigate(-1)
                } else {
                    dispatch(getActionSetConfirm(resp.error))
                }
            })
    }

    return (
        <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
            <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                {t('CreatePlay02')}
            </Typography>
            <Divider />
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    id="title"
                    name="title"
                    label={t('CreatePlay03')}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    size="small"
                    sx={{ marginTop: 3 }}
                    inputProps={{ maxLength: 45 }}
                />
                <TextField
                    fullWidth
                    multiline
                    id="field_play_description"
                    name="field_play_description"
                    label={t('CreatePlay04')}
                    type="field_play_description"
                    value={formik.values.field_play_description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.field_play_description && Boolean(formik.errors.field_play_description)}
                    helperText={formik.touched.field_play_description && formik.errors.field_play_description}
                    size="small"
                    sx={{ marginTop: 2 }}
                    inputProps={{ maxLength: 600 }}
                />
                <TextField
                    fullWidth
                    select
                    id="field_play_group"
                    name="field_play_group"
                    label={t('CreatePlay05')}
                    type="field_play_group"
                    value={state.allGroups.length === 0 ? '' : formik.values.field_play_group} // don't use default value until select has default value
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.field_play_group && Boolean(formik.errors.field_play_group)}
                    helperText={formik.touched.field_play_group && formik.errors.field_play_group}
                    size="small"
                    sx={{ marginTop: 2 }}
                >
                    {/* get list of all 'play' groups */}
                    {
                        state.allGroups.filter(x => x.attributes.field_group_type === OperationMode.play).map((item, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={item.id}
                                >
                                    {item.attributes.title}
                                </MenuItem>
                            )
                        })
                    }
                </TextField>

                {/* IN THIS ONE WE IMPORT THE OPEN MODAL FUNCTION FROM PLAYBUILDER */}
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => setOpenDialogPlayBuilder(true)}
                    sx={{
                        textTransform: 'none',
                        marginTop: 2,
                        justifyContent: 'flex-start' // Align icon and text to the left
                    }}
                >
                    <BorderColorRoundedIcon sx={{ marginRight: 1 }} /> {/* Add space between icon and text */}
                    {
                        props.play.id ? t('CreatePlay06') : t('CreatePlay07')
                    }

                </Button>

                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                    <Button sx={{ textTransform: 'none', fontWeight: 'bold' }} size="small" color="primary" variant="contained" type="submit">
                        {t('CreatePlay08')}
                    </Button>
                    {
                        // show delete button if current play has an id. Otherwise, we are creating a new play
                        props.play.id &&
                        <Box sx={{ display: 'flex', marginLeft: 1 }}>
                            <Button sx={{ textTransform: 'none' }} color="primary" variant="outlined" size="small" onClick={handleDelete}>
                                {t('CreatePlay09')}
                            </Button>
                        </Box>
                    }
                </Box>


                {/* <Button color="primary" variant="contained" fullWidth onClick={handleClickBuild}>
                    Build
                </Button> */}
            </form>
            {/* <span>{JSON.stringify(formik.values, null, 2)}</span> */}
            {/* <SnackbarMessages
                message={t('play has been saved')}
                open={openSnackbarMessage}
                icon={<PlaylistAddCheckCircleRoundedIcon fontSize="small" sx={{ color: '#2e7d36' }} />}
                color={'#2e7d36'}
            /> */}

            <DialogPlayBuilder
                open={openDialogPlayBuilder}
                onClose={(playDetails: PlayDetails | null) => {
                    if (playDetails) {
                        setPlayDetails(playDetails)
                    }
                    setOpenDialogPlayBuilder(false)
                }
                }
            />

        </Box>

    );
}
