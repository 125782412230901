import { Box, CardMedia, Fab, Paper, Typography, useTheme } from '@mui/material';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { ActionSetUserData, DrupalEntity, JSONAPITypeId, PropsExerciseCard, TypeContext } from '../misc/Types';
import { Context } from '../App';
import { exerciseImage, getDD } from '../misc/Functions';
import { BACKEND } from '../misc/Constants';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import EditIcon from '@mui/icons-material/Edit';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useTranslation } from 'react-i18next';
import log from "../misc/Logger";
import { DialogExerciseDetails } from './DialogExerciseDetails';
import DialogExerciseDurationAndFocus from './DialogExerciseDurationAndFocus';
import { DialogShowVideo } from './DialogShowVideo';
import { Tooltip } from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FiberNewRoundedIcon from '@mui/icons-material/FiberNewRounded';
import AuthorImageAndName from './AuthorImageAndName';

export default function ExerciseCard(props: PropsExerciseCard) {
  const { state, dispatch } = useContext(Context) as TypeContext;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const [openDialogExerciseDetails, setOpenDialogExerciseDetails] = useState(false)
  const [openDialogExerciseDurationAndFocus, setOpenDialogExerciseDurationAndFocus] = useState(false);
  const [conceptFileID, setConceptFileID] = useState('') // Controls open/close of DialogShowVideo
  const [concept, setConcept] = useState('') // Title for video dialog

  // Value for repostion of video play icon 
  // const [videoPlayIconLeft, setVideoPlayIconLeft] = useState(0);
  // Initially show exercise description. In portrait mode there is an option not to show exercise description
  const [showExerciseDescription, setShowExerciseDescription] = useState(false);
  const [showConcepts, setShowConcepts] = useState(false);
  const [disabled, setDisabled] = useState(false)
  const [imageLoadTime, setImageLoadTime] = useState(new Date().getTime())
  const imageRef = useRef<HTMLImageElement>(null);
  const fileIDVideo = props.exercise.relationships.field_exercise_video.data?.id;
  const fileIDImage = props.exercise.relationships.field_exercise_board_image.data?.id;

  // Favorite has been flopped. Reenable favorites button
  useEffect(() => {
    setDisabled(false)
  }, [state.user.data])

  // Set flag on new exercises. Determined by configuration
  const newExercise = () => {
    let nowMinusXDays = Date.now() - parseInt(state.configuration[0].attributes.field_days_to_flag_new_exercises) * 24 * 60 * 60 * 1000;
    return (Date.parse(props.exercise.attributes.created) > nowMinusXDays || Date.parse(props.exercise.attributes.changed) > nowMinusXDays)
  }

  // Set flag on new concept. Determined by configuration
  function newConcept(concept: DrupalEntity): boolean {
    let nowMinusXDays = Date.now() - parseInt(state.configuration[0].attributes.field_days_to_flag_new_concept) * 24 * 60 * 60 * 1000;
    return (Date.parse(concept.attributes.created) > nowMinusXDays || Date.parse(props.exercise.attributes.changed) > nowMinusXDays)
  }

  function getRelationshipsDataElementFor(node: DrupalEntity) {
    const dataElement: JSONAPITypeId = {
      type: node.type,
      id: node.id || '',
      meta: {
        drupal_internal__target_id: node.attributes.drupal_internal__nid
      }
    }
    return dataElement
  }

  return (
    <Fragment>

      <DialogExerciseDetails
        open={openDialogExerciseDetails}
        onClose={() => setOpenDialogExerciseDetails(false)}
        exercise={props.exercise}
        addOption={true}
      />

      <DialogExerciseDurationAndFocus
        open={openDialogExerciseDurationAndFocus}
        onClose={() => setOpenDialogExerciseDurationAndFocus(false)}
        exercise={props.exercise}
      />

      <DialogShowVideo
        open={conceptFileID !== ''}
        onClose={() => setConceptFileID('')}
        fileIDVideo={conceptFileID}
        concept={concept}
      />

      <Paper
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '350px',
          margin: 'auto',
          padding: state.portrait ? 1 : 1.5,
          borderRadius: state.portrait ? 2 : 5,
        }}
      >

        <Box display={'flex'}>
          <AuthorImageAndName node={props.exercise} />

          {/* New exercise */}
          <Box marginTop={'auto'} marginBottom={'auto'}>
            {
              newExercise() ?
                <FiberNewRoundedIcon color={'secondary'} />
                : <></>
            }
          </Box>
        </Box>

        {/* Image. If video available and hover then show video */}
        <Box position='relative'>
          <CardMedia
            sx={{ display: 'block', maxHeight: '300px', objectFit: 'contain', margin: 'auto', borderRadius: 2 }}
            component='img'
            src={exerciseImage(state, fileIDImage, props.exercise.id)}
            // If image is not jet on CDN then retry in a 5s.
            onError={() => {
              if (state.exerciseIDLastCRUD === props.exercise.id) {
                const waitTime = new Date().getTime() - imageLoadTime
                log.info(`reload image (${waitTime}ms) : ${exerciseImage(state, fileIDImage)}`);
                if (waitTime > 30000) {
                  setImageLoadTime(Number.MAX_SAFE_INTEGER) // log error only once
                  log.error(`Error reload image (${waitTime}ms) : ${exerciseImage(state, fileIDImage)}`);
                }
                setTimeout(() => {
                  const src = exerciseImage(state, fileIDImage, props.exercise.id);
                  if (imageRef && imageRef.current && imageRef.current.src)
                    imageRef.current.src = src;
                }, 5000)
              }
            }}
            alt={`${t('ExerciseCard05')} ${state.exerciseIDLastCRUD === props.exercise.id ? t('ExerciseCard06') : ''}`}
            ref={imageRef}
            onClick={() => setOpenDialogExerciseDetails(true)}
          />
          {/* reposition video play icon 36px becuase it fits well with icon size of 24x24, ie PlayCircleOutlineIcon */}
          {/* <PlayCircleOutlineIcon sx={{ display: fileIDVideo ? 'block' : 'none', position: 'relative', top: '-36px', left: `${videoPlayIconLeft}px` }} /> */}
          <PlayCircleOutlineIcon sx={{ display: fileIDVideo ? 'block' : 'none', position: 'absolute', bottom: '4px', left: '4px' }} />
          <Tooltip title="Add drill to the Practice Planner" enterDelay={1000}>
            <Fab
              color="secondary"
              size="small"
              sx={{
                position: 'absolute',
                bottom: '4px',
                right: '4px',
                opacity: 0.7, // para añadir transparencia
                transition: 'opacity 0.3s',
                '&:hover': {
                  opacity: 0.9,
                  backgroundColor: '#DD6F20',
                  color: 'white',
                },
              }}
              onClick={() => setOpenDialogExerciseDurationAndFocus(true)}
            >
              <AddRoundedIcon />
            </Fab>
          </Tooltip>
        </Box>

        {/* Use marginTop -2 because we pull text up into area where video play icon is before icon is repositioned */}
        <Box sx={{ display: 'flex', marginTop: 1 }}>

          {/* Edit icon */}
          {
            props.exercise.relationships.uid.data.id === state.user.data.id &&
            <IconButton size='small' onClick={() => { navigate("/exercisecreate", { state: props.exercise }); }}>
              <EditIcon />
            </IconButton>
          }

          {/* Favorite icon */}
          {
            props.exercise.relationships.uid.data.id !== state.user.data.id &&
            <IconButton
              aria-label='flopFavorite'
              size='small'
              disabled={disabled}
              // onClick={() => clickFavoriteIcon()}
              onClick={() => {
                // try function in new custom module icb_user
                setDisabled(true)
                getDD(state, dispatch, `${BACKEND}/icb-user/flop_favorite/${props.exercise.attributes.drupal_internal__nid}`)
                  .then(() => {
                    const field_favorite_exercises: JSONAPITypeId[] = state.user.data.relationships.field_favorite_exercises.data.find((x: JSONAPITypeId) => x.id === props.exercise.id) //inFavorites(state, props.exercise.id!)
                      ? state.user.data.relationships.field_favorite_exercises.data.filter((x: JSONAPITypeId) => x.id !== props.exercise.id)
                      : state.user.data.relationships.field_favorite_exercises.data.concat([getRelationshipsDataElementFor(props.exercise)])
                    const user: DrupalEntity = {
                      ...state.user.data,
                      relationships: {
                        ...state.user.data.relationships,
                        field_favorite_exercises: {
                          data: field_favorite_exercises
                        }
                      }
                    }
                    const action: ActionSetUserData = { type: 'setUserData', userData: user }
                    dispatch(action)
                  })
              }}
            >
              {state.user.data.relationships.field_favorite_exercises.data.find((x: JSONAPITypeId) => x.id === props.exercise.id)
                ? <FavoriteIcon style={{ color: 'red' }} />
                : <FavoriteBorderIcon />}
            </IconButton>
          }

          {/* Exercise Title. Use 'auto' on top and buttom margin to center text vertically */}
          <Typography sx={{ fontSize: { xs: 11, sm: 14 }, lineHeight: 1, fontWeight: 'bold', marginTop: 'auto', marginBottom: 'auto', width: 1 }} >
            {props.exercise.attributes.title}
          </Typography>

          {/* More icon to show concepts if there are any */}
          {
            props.exercise.relationships.field_concepts.data.length > 0 && (
              <Tooltip title={t('ExerciseCard11')}>
                <IconButton size='small' onClick={() => setShowConcepts(!showConcepts)}> {/* Cambiar a showConcepts */}
                  {showConcepts ? <TipsAndUpdatesIcon fontSize='small' color='primary' /> : <TipsAndUpdatesOutlinedIcon fontSize='small' />}
                </IconButton>
              </Tooltip>
            )
          }

        </Box>

        {!state.portrait && (
          <>
            <Typography sx={{ fontSize: { xs: 11, sm: 13 }, lineHeight: 1.4, whiteSpace: 'pre-wrap' }} marginLeft={0.5}>
              {showExerciseDescription
                ? props.exercise.attributes.field_description
                : props.exercise.attributes.field_description?.substring(0, 150) + (props.exercise.attributes.field_description?.length > 150 ? '...' : '')
              }
            </Typography>
            {props.exercise.attributes.field_description?.length > 150 && (
              <Typography
                onClick={() => setShowExerciseDescription(!showExerciseDescription)}
                sx={{
                  textTransform: 'none',
                  paddingLeft: 0.5,
                  paddingBottom: 1,
                  fontSize: '12px',
                  cursor: 'pointer',
                  color: theme.palette.text.secondary,
                  textDecoration: 'underline',
                }}
              >
                {showExerciseDescription ? t('ExerciseCard10') : t('ExerciseCard09')}
              </Typography>
            )}
          </>
        )}

        {/* Concept. Show buttons for each exercise concept */}
        {showConcepts && (
          <Box display="flex" justifyContent="center" flexWrap="wrap" gap={0.5} marginTop={1}>
            {
              props.exercise.relationships.field_concepts.data.map((conceptRef: JSONAPITypeId) => (state.allConcepts.find(x => x.id === conceptRef.id))).map((concept: DrupalEntity, index: number) => (
                <Fragment key={index}>
                  <Tooltip
                    title={newConcept(concept) ? `${t('ExerciseCard07')} "${concept.attributes.title}" ${t('ExerciseCard08')}` : ''}
                    enterDelay={500}
                  >
                    <Typography
                      variant='body2'
                      sx={{
                        fontSize: { xs: 9, sm: 10 },
                        textTransform: 'none',
                        fontWeight: 'bold',
                        borderRadius: '20px',
                        cursor: 'pointer',
                        padding: '2px 6px',
                        transition: 'opacity 0.3s',
                        //border: '1px solid #eeb68e',
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.background.paper,
                        opacity: 0.8, // para añadir transparencia
                        //border: newConcept(concept) ? '1px solid #eeb68e' : `1px solid ${theme.palette.divider}`,
                        '&:hover': {
                          backgroundColor: theme.palette.primary.main,
                          opacity: 1,
                        }
                      }}
                      onClick={() => {
                        setConceptFileID(concept.relationships.field_concept_video.data.id)
                        setConcept(concept.attributes.title)
                        log.info('DialogShowVideo() - ' + concept.attributes.title);
                      }}
                    >
                      {concept.attributes.title}
                    </Typography>
                  </Tooltip>
                </Fragment>
              ))
            }
          </Box>
        )}

        {/* Create flexbox item that will take up as much space as possible. Thereby pressing the following flexbox item all the way down */}
        <Box height={1}></Box>

      </Paper>
    </Fragment >
  );
}
