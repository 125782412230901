import { useState, useEffect } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

interface Frame {
  id: number;
  players: any[];
  lines: any[];
}

interface PlayAnimationModalProps {
  isOpen: boolean;
  onClose: () => void;
  frames: Frame[];
  generateSVGContent: (framesData: Frame[]) => string;
  generateAnimationScript: (framesData: Frame[]) => string;
}

export default function PlayAnimationModal({ isOpen, onClose, frames, generateSVGContent, generateAnimationScript }: PlayAnimationModalProps) {
  const [modalContentUrl, setModalContentUrl] = useState<string | null>(null);
  const location = useLocation();


  useEffect(() => {
    if (isOpen) {
      const htmlContent = `
        <!DOCTYPE html>
        <html>
        <head>
          <meta charset="UTF-8">
          <title>SVG Animation</title>
          <style>
            /* Optional: Add some basic styling */
            #controls {
              text-align: center;
              margin-top: 10px;
            }
            #play-pause {
              padding: 5px 10px;
            }
            #progress-bar {
              width: 80%;
              margin-top: 10px;
            }
          </style>
        </head>
        <body>
          ${generateSVGContent(frames)}
        
          <!-- Controls -->
          <div id="controls">
            <button id="play-pause">Pause</button>
            <input type="range" id="progress-bar" min="0" max="1000" value="0">
          </div>
        
          <script>
            ${generateAnimationScript(frames)}
          </script>
        </body>
        </html>
      `;

      const blob = new Blob([htmlContent], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      setModalContentUrl(url);
    } else {
      if (modalContentUrl) {
        URL.revokeObjectURL(modalContentUrl);
        setModalContentUrl(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, frames]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="animation-modal-title"
      aria-describedby="animation-modal-description"
    >

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '600px',
          maxHeight: '600px',
          width: '95%',
          height: '95%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 1
        }}
      >
        <Typography variant="h6" id="animation-modal-title">
          {location.state.attributes.title}
        </Typography>
        {modalContentUrl && (
          <iframe
            src={modalContentUrl}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="Animation"
          />
        )}
      </Box>
    </Modal>
  );
};
