import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import log from "../misc/Logger";
import { Context } from '../App';
import { ActionSetSelectedPlays, CRUD, DrupalEntity, TypeContext } from '../misc/Types';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { getActionSetConfirm, nodeCRUD } from '../misc/Functions';
import SnackbarMessages from './SnackbarMessages';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import PlayCard from './PlayCard';
import { DRUPALENTITYINIT } from '../misc/Constants';
import { useTheme } from '@mui/material/styles';
// import { DRUPALENTITYINIT } from '../misc/Constants';
// import { useNavigate } from 'react-router-dom';

export default function CreatePlaybook() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('CreatePlaybook');
    const theme = useTheme();

    const [playbook, setPlaybook] = useState(DRUPALENTITYINIT)
    const [openSnackbarMessage, setOpenSnackbarMessage] = useState(false)

    const validationSchema = yup.object({
        title: yup
            .string()
            .required('CreatePlay00')
            // allow save if no other playbook with same name
            .test('new-play', t("PLAY - ${path} name exists already"), (value) => {
                const rc = state.allPlays.find(x => x.attributes.title === value) === undefined
                return rc
            }),
        field_playbook_description: yup
            .string(),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            field_playbook_description: '',
            plays: ['p1', 'p2'],
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log('onsubmit', values)
            const field_plays = state.selectedPlays.map((item) => {
                return {
                    type: 'node--play',
                    id: item.id
                }
            })
            let node: DrupalEntity = {
                type: 'node--playbook',
                attributes: {
                    title: values.title,
                    field_playbook_description: values.field_playbook_description
                }
            }
            let CRUDOperation = CRUD.Create
            if (playbook.id) {
                node['id'] = playbook.id
                CRUDOperation = CRUD.Update
            }
            if (field_plays)
                node['relationships'] = {
                    field_plays: {
                        data: field_plays
                    }
                }
            nodeCRUD(state, dispatch, CRUDOperation, node)
                .then((resp) => {
                    if (resp.data) {
                        setPlaybook(resp.data)
                        setOpenSnackbarMessage(true);
                        setTimeout(() => { setOpenSnackbarMessage(false); }, 1500)
                    } else {
                        dispatch(getActionSetConfirm(resp));
                    }
                })
        }
    });

    // function handleClickBuild() {
    //     if (!playbook.id)
    //         dispatch(getActionSetConfirm(t('save play before going to play builder')));
    //     else
    //         navigate('/playbuild', { state: playbook });
    // }

    return (
        <Grid container direction="row" alignItems="stretch" style={{ marginTop: 89, marginBottom: 150 }} >
            {!state.portrait && (<Divider orientation="vertical" flexItem sx={{ minHeight: '100vh' }} />)}
            <Grid item xs margin={1}> {/* Wrap Card in a Grid item */}

                <Box sx={{ backgroundColor: '#e1f5fe', padding: 0.5, borderRadius: 1, border: '1px solid ' + theme.palette.divider, }}>
                    <Typography textAlign={'center'} sx={{ fontSize: { xs: 20, sm: 23 }, fontFamily: 'PT Sans, sans-serif', fontWeight: 'bold', color: '#15468f' }}>
                        {t('CreatePlaybook02')}
                    </Typography>
                </Box>

                <form onSubmit={formik.handleSubmit}>

                    <TextField
                        size='small'
                        variant='outlined'
                        fullWidth
                        id="title"
                        name="title"
                        label={t('CreatePlaybook03')}
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                        sx={{ marginTop: 1 }}
                        inputProps={{ maxLength: 45 }}
                    />

                    <TextField
                        size='small'
                        fullWidth
                        multiline
                        id="field_playbook_description"
                        name="field_playbook_description"
                        label={t('CreatePlaybook04')}
                        value={formik.values.field_playbook_description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.field_playbook_description && Boolean(formik.errors.field_playbook_description)}
                        helperText={formik.touched.field_playbook_description && formik.errors.field_playbook_description}
                        sx={{ marginTop: 1 }}
                        inputProps={{ maxLength: 600 }}
                    />

                    <Grid item xs={12} display={'flex'} justifyContent={'space-evenly'} >

                        <Button
                            sx={{
                                textTransform: 'none',
                                fontSize: '0.8rem',
                                height: '25px',
                                color: theme.palette.text.primary,
                                border: '1px solid ' + theme.palette.divider,
                                fontWeight: 'bold',
                                marginTop: 1,
                                marginBottom: 1,
                                '&:hover': {
                                    backgroundColor: theme.palette.action.selected,
                                }
                            }}
                            type="submit">
                            {t('CreatePlaybook01')}
                        </Button>

                        {/* Clear list of plays */}
                        <Button
                            onClick={() => {
                                dispatch(getActionSetConfirm(t('CreatePlaybook06'), 'ok', () => {
                                    const action: ActionSetSelectedPlays = { type: 'setSelectedPlays', plays: [] }
                                    dispatch(action)
                                }));
                            }}
                            sx={{
                                textTransform: 'none',
                                fontSize: '0.8rem',
                                height: '25px',
                                color: theme.palette.text.primary,
                                border: '1px solid ' + theme.palette.divider,
                                fontWeight: 'bold',
                                marginTop: 1,
                                marginBottom: 1,
                                '&:hover': {
                                    backgroundColor: theme.palette.action.selected,
                                }
                            }}>
                            {t('CreatePlaybook05')}
                        </Button>
                    </Grid>

                    {state.selectedPlays.length === 0 &&
                        <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', textAlign: 'center' }}>
                            <Typography sx={{ paddingTop: 0, color: theme.palette.text.secondary, fontSize: 13 }}>
                                {t('CreatePlaybook07')}
                            </Typography>
                        </Box>
                    }

                    {
                        state.selectedPlays.map((item, index) => {
                            return (
                                <PlayCard
                                    key={index}
                                    index={index}
                                    play={item}
                                />
                            )
                        })
                    }

                    {/* <Button color="primary" variant="contained" fullWidth onClick={handleClickBuild}>
                    Build
                </Button> */}
                </form>
            </Grid>


            {/* <span>{JSON.stringify(formik.values, null, 2)}</span> */}

            <SnackbarMessages
                message={t('CreatePlaybook00')}
                open={openSnackbarMessage}
                icon={<PlaylistAddCheckCircleRoundedIcon fontSize="small" sx={{ color: '#2e7d36' }} />}
                color={'#2e7d36'}
            />
        </Grid>
    );
}
