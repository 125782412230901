import { Typography, Button, IconButton } from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { TypeContext } from '../../misc/Types';
import { Context } from '../../App';
import { useTheme } from '@mui/material/styles';
interface PhasesSectionPlayBuilderProps {
    frames: any[];
    currentFrameIndex: number;
    handleSelectFrame: (index: number) => void;
    handleDeleteFrame: (index: number) => void;
    handleCreateNewFrame: () => void;
}

export default function PhasesSectionPlayBuilder({ frames, currentFrameIndex, handleSelectFrame, handleDeleteFrame, handleCreateNewFrame }: PhasesSectionPlayBuilderProps) {
    const location = useLocation();
    const { state } = useContext(Context) as TypeContext;
    const theme = useTheme();

    return (
        <div>
            {!state.portrait ? (
                <>
                    <Typography variant="h6" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        Phases
                    </Typography>
                    {frames.map((frame, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '5px',
                            }}
                        >
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={() => handleSelectFrame(index)}
                                style={{
                                    flex: 1,
                                    backgroundColor:
                                        index === currentFrameIndex ? theme.palette.primary.main : '#e7e7e7',
                                    color: index === currentFrameIndex ? 'white' : 'black',
                                    marginRight: '5px',
                                    textTransform: 'none',
                                    borderColor: '#90a4ae'
                                }}
                            >
                                Phase {frame.id}
                            </Button>

                            <IconButton
                                onClick={() => handleDeleteFrame(index)}
                                size="small"
                                style={{ color: '#b0bec5' }}
                            >
                                <DeleteOutlineRoundedIcon />
                            </IconButton>
                        </div>
                    ))}

                    <Button fullWidth variant="outlined" onClick={handleCreateNewFrame}>
                        Next Phase
                    </Button>
                </>
            ) : (
                <>
                    {state.portrait &&
                        <Typography style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '5px' }}>
                            {location.state.attributes.title}
                        </Typography>
                    }
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            maxWidth: '480px',
                            margin: 'auto',
                        }}
                    >
                        <IconButton
                            onClick={() => handleDeleteFrame(currentFrameIndex)}
                            size="small"
                            style={{ color: '#b0bec5' }}
                        >
                            <DeleteOutlineRoundedIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => handleSelectFrame(currentFrameIndex - 1)}
                            size="small"
                            style={{ color: '#b0bec5' }}
                            disabled={currentFrameIndex === 0}
                        >
                            <KeyboardArrowLeftRoundedIcon />
                        </IconButton>

                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                            Phase {frames[currentFrameIndex].id}
                        </Typography>

                        <IconButton
                            onClick={() => handleSelectFrame(currentFrameIndex + 1)}
                            size="small"
                            style={{ color: '#b0bec5' }}
                            disabled={currentFrameIndex === frames.length - 1}
                        >
                            <KeyboardArrowRightRoundedIcon />
                        </IconButton>

                        <IconButton
                            onClick={handleCreateNewFrame}
                            size="small"
                            style={{ color: theme.palette.primary.main }}
                        >
                            <AddBoxRoundedIcon />
                        </IconButton>
                    </div>
                </>
            )}

        </div>
    );
}
