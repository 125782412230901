import { useContext, } from 'react';
import Exercises from "./Exercises";
import PracticeProgram from './PracticeProgram';
import { OperationMode, TypeContext } from '../misc/Types';
import { Context } from '../App';
import Grid2 from '@mui/material/Unstable_Grid2';
import log from "../misc/Logger";
import { Box } from '@mui/material';
import CreatePlaybook from './CreatePlaybook';

export default function FrontPage() {
    const { state } = useContext(Context) as TypeContext;
    log.debug('FrontPage()');

    return (
        <Box>
            {/* Display this in portrait mode */}
            {state.portrait && state.showPracticeProgram &&
                <Box paddingBottom={'40%'} sx={{ minHeight: '100vh' }}>
                    {
                        state.operationMode === OperationMode.exercise
                            ? <PracticeProgram />
                            : <CreatePlaybook />
                    }
                </Box>
            }
            {state.portrait && !state.showPracticeProgram && (
                <Box>
                    <Box paddingBottom={1} sx={{ minHeight: '100vh' }}>
                        <Exercises />
                    </Box>
                </Box>
            )}

            {/* Display this in landscape mode */}
            <Box>
                {!state.portrait && (
                    <Box>
                        <Grid2 container>
                            <Grid2 xs={state.showPracticeProgram ? 8 : 12} style={{ transition: 'width 0.3s ease-in-out' }}>
                                <Box sx={{ overflowY: 'scroll', height: '100vh' }}>
                                    <Exercises />
                                </Box>
                            </Grid2>
                            <Grid2 xs={state.showPracticeProgram ? 4 : 0} style={{
                                transition: 'width 0.3s ease-in-out',
                                //whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}>
                                <Box sx={{ overflowY: 'scroll', height: '100vh' }}>
                                    {
                                        state.operationMode === OperationMode.exercise
                                            ? <PracticeProgram />
                                            : <CreatePlaybook />
                                    }
                                </Box>
                            </Grid2>
                        </Grid2>
                    </Box>
                )}
            </Box>
        </Box>
    )
}
