import { Fragment, useContext, useState } from 'react';
import { Button, Divider, Paper, Typography } from '@mui/material';
import { DirectionsRun, ContentPaste, AddCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { TypeContext } from '../misc/Types';
import { Context as AppContext } from '../App';

type UserType = 'free' | 'pro' | 'club' | 'clubadmin';

type MenuEntry = MenuItemEntry | 'divider';

interface MenuItemEntry {
    isVisible: boolean,
    icon: JSX.Element;
    textKey: string;
}

export default function UserflowDropdown() {
    const { t } = useTranslation();
    const { state } = useContext(AppContext) as TypeContext;
    const [showPaper, setShowPaper] = useState(false);

    const userType = state.user.data.attributes.field_user_type as UserType;

    const renderMenuEntries = (entries: MenuEntry[]) => {
        // const ent = entries.filter(x => typeof x !== 'string' && x.isVisible)
        return entries.map((entry, index) => {
            if (typeof entry === 'string' && entry === 'divider') {
                return <Divider key={`divider-${index}`} />;
            }

            const item = entry as MenuItemEntry;
            return entry.isVisible ? (
                <Button
                    key={`item-${index}`}
                    variant="text"
                    fullWidth
                    //startIcon={item.icon}
                    style={{ marginTop: index === 0 ? 0 : 5, textTransform: 'none', justifyContent: 'flex-start' }}
                >
                    <Typography fontSize={14} style={{ fontFamily: 'PT Sans, sans-serif', }}>
                        {t(item.textKey)}
                    </Typography>
                </Button>
            ) : null;
        });
    };

    const MENU_ITEMS: Record<UserType, MenuEntry[]> = {
        free: [
            { isVisible: true, icon: <ContentPaste fontSize="small" />, textKey: 'UserflowDropdown01' },
            { isVisible: true, icon: <AddCircle fontSize="small" />, textKey: 'UserflowDropdown02' },
            // { isVisible: true, icon: <Equalizer fontSize="small" />, textKey: 'UserflowDropdown03' },
            // 'divider',
            // { isVisible: !state.nativeApp, icon: <GroupAdd fontSize="small" />, textKey: 'UserflowDropdown04' },
            // { isVisible: !state.nativeApp, icon: <WorkspacePremium fontSize="small" />, textKey: 'UserflowDropdown05' },
            // { isVisible: !state.nativeApp, icon: <Diversity3 fontSize="small" />, textKey: 'UserflowDropdown06' },
        ],

        pro: [
            { isVisible: true, icon: <ContentPaste fontSize="small" />, textKey: 'UserflowDropdown01' },
            { isVisible: true, icon: <AddCircle fontSize="small" />, textKey: 'UserflowDropdown02' },
            // { isVisible: true, icon: <Equalizer fontSize="small" />, textKey: 'UserflowDropdown03' },
            // 'divider',
            // { isVisible: !state.nativeApp, icon: <Diversity3 fontSize="small" />, textKey: 'UserflowDropdown06' },
        ],

        club: [
            { isVisible: true, icon: <ContentPaste fontSize="small" />, textKey: 'UserflowDropdown01' },
            { isVisible: true, icon: <AddCircle fontSize="small" />, textKey: 'UserflowDropdown02' },
            // { isVisible: true, icon: <Equalizer fontSize="small" />, textKey: 'UserflowDropdown03' },
            // 'divider',
            // { isVisible: !state.nativeApp, icon: <Diversity3 fontSize="small" />, textKey: 'UserflowDropdown06' },
        ],

        clubadmin: [
            { isVisible: true, icon: <ContentPaste fontSize="small" />, textKey: 'UserflowDropdown01' },
            { isVisible: true, icon: <AddCircle fontSize="small" />, textKey: 'UserflowDropdown02' },
            // { isVisible: true, icon: <Equalizer fontSize="small" />, textKey: 'UserflowDropdown03' },
            // 'divider',
            // { isVisible: !state.nativeApp, icon: <GroupAdd fontSize="small" />, textKey: 'UserflowDropdown07' },
        ],
    };

    return (
        <Fragment>
            <Button
                variant="outlined"
                fullWidth
                style={{ marginTop: 20, textTransform: 'none', justifyContent: 'flex-start' }}
                startIcon={<DirectionsRun />}
                onClick={() => setShowPaper(!showPaper)}
            >
                <Typography fontSize={14} style={{ fontFamily: 'PT Sans, sans-serif' }}>
                    {t('UserflowDropdown00')}
                </Typography>
            </Button>

            {showPaper && (
                <Paper elevation={0} sx={{ marginTop: 1 }}>
                    {renderMenuEntries(MENU_ITEMS[userType])}
                </Paper>
            )}
        </Fragment>
    );
}
