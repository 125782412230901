import log from 'loglevel';                     // logging, basesystem
import remote from 'loglevel-plugin-remote';    // logging, remote plugin
import prefix from 'loglevel-plugin-prefix';    // logging, prefix plugin
import { BACKEND } from './Constants';

// Log to Drupal backend 
remote.apply(log, {url: `${BACKEND}/logger`, timestamp: () => '',});
// Prefix for log lines. Default to Anonymous. Change when we know who logs on
prefix.reg(log)
prefix.apply(log, {template: 'Anonymous (0)'})

// Default log level
log.setLevel('info');
// log.setLevel('debug');

export default log;
